import {
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import BackArrow from "../../assets/images/add-cryptos/backButton.svg";
import SearchIcon from "../../assets/images/add-cryptos/search-icon.svg";
import BgImage from "../../assets/images/add-cryptos/add-crypto-bg-image.png";
import StockGroup from "../../assets/images/add-cryptos/stock-group.svg";
import DragImage from "../../assets/images/add-cryptos/drag-image.png";
import AddIcon from "../../assets/images/add-cryptos/add-icon.svg";
import {
  StylesAddCryptoHeader,
  StylesAddCryptoHeaderWrapper,
  StylesAddNewStocks,
  StylesApp,
  StylesButton,
  StylesFormWrapper,
  StylesSaveCrypto,
  StylesSaveCryptosRightSide,
  StylesSavedStocks,
  StylesSavedStocksList,
  StylesSearchBar,
  StylesSelectedSaveCryptos,
  StylesStocksList,
  StylesStocksListLeftSection,
  StylesStocksListRightSection,
  StylesViewWrapper,
} from "./styles";
import { UserContext } from "../context/ContextApi";
import InfiniteScroll from "react-infinite-scroll-component";
import {
  addCryptoToUser,
  calculateCryptos,
  getUserETFs,
  getUserETFsCryptos,
} from "../../services/api";

import { analytics } from "../../firebaseConfig";

import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import Loader from "../Loader";
import { Tooltip } from "react-tooltip";
import ShareIcon from "../../assets/images/add-cryptos/share.svg";
import Share from "../share";
import { PopUp } from "../share/styles";

function AddCryptoHeaderButton({
  setIsSaveCryptoLoading,
  isSaveCryptoLoading,
}) {
  const {
    selectedCryptos,
    isSavingStocks,
    setIsSavingStocks,
    isSelectingStocks,
    content,
    addSelectedViewType,
    addSelectedScheme,
    investedAmount,
    setContent,
    initialValues,
    setInitialValues,

    setContentError,
  } = useContext(UserContext);
  const navigate = useNavigate();

  // const getCryptoIds = async () => {
  //   const cryptoIdsArray = await selectedCryptos.map((data) => data.id);
  //   return cryptoIdsArray;
  // };

  const addSelectedCryptosProperties = () => {
    const viewType = addSelectedViewType();
    let selectedWeightage = [];
    if (viewType === "equal") {
      for (let i = 0; i < initialValues.length; i++) {
        selectedWeightage.push(
          initialValues.length > 0 && 1 / initialValues.length
        );
      }
    } else if (viewType === "marketcap") {
      for (let i = 0; i < initialValues.length; i++) {
        selectedWeightage.push(0);
      }
    } else if (viewType === "custom") {
      for (let i = 0; i < initialValues.length; i++) {
        selectedWeightage.push(initialValues[i].weightage / 1);
      }
    }
    const selectedCryptosData = [];

    for (let i = 0; i < selectedCryptos.length; i++) {
      selectedCryptos.length > 0 &&
        selectedCryptosData.push({
          id: selectedCryptos.length > 0 && selectedCryptos[i]?.id,
          selectedWeightage: parseFloat(selectedWeightage[i]),
          // tokensCount: Number(1),
        });
    }
    return selectedCryptosData;
  };

  const saveCryptos = async () => {
    setIsSaveCryptoLoading(true);
    try {
      if (content === "") {
        setContentError("Enter ETF name");
        return;
      }
      const AddedCryptos = await addCryptoToUser(
        addSelectedCryptosProperties(),
        content,
        addSelectedViewType(),
        addSelectedScheme(),
        investedAmount
      );
      setIsSaveCryptoLoading(false);
      if (!isSaveCryptoLoading) {
        setContent("My ETF");
        navigate("/dashboard");
      }
      return AddedCryptos;
    } catch (error) {
      setIsSaveCryptoLoading(true);

      console.error(error);
    } finally {
      setIsSaveCryptoLoading(false);
    }
  };

  const title =
    isSavingStocks && selectedCryptos.length > 0 ? (
      isSaveCryptoLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
            width: "50px",
          }}
        >
          <Loader height={"20px"} width={"20px"} />
        </div>
      ) : (
        <span onClick={saveCryptos}>Save</span>
      )
    ) : (
      "Next"
    );

  const handleOnClick = () => {
    if (isSelectingStocks && selectedCryptos.length > 0) {
      setIsSavingStocks(true);
    }
  };

  return (
    <StylesButton
      button_border={selectedCryptos.length > 0 && "#087AF9"}
      button_bg_color={selectedCryptos.length > 0 && "#087AF9"}
      button_text_color={selectedCryptos.length > 0 && "#fff"}
      onClick={() => {
        handleOnClick();
        logEvent(analytics, "save_Etf");
      }}
    >
      {title}

      {isSaveCryptoLoading ? (
        ""
      ) : (
        <svg
          width="7"
          height="10"
          viewBox="0 0 7 10"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M1 9L5.02391 5.78087C5.52432 5.38054 5.52432 4.61946 5.02391 4.21913L1 1"
            stroke={selectedCryptos.length > 0 ? "#fff" : "#434343"}
            stroke-width="1.5"
            stroke-linecap="round"
          />
        </svg>
      )}
    </StylesButton>
  );
}

function AddCryptoHeader({ setIsSaveCryptoLoading, isSaveCryptoLoading }) {
  const {
    searchedStock,
    setSearchedStock,
    isSavingStocks,
    setIsSelectingStocks,
    setIsSavingStocks,
    selectedCryptos,
    setSelectedCryptos,
    setSearchedCryptosArray,
    isAmountInvested,
    setIsAmountInvested,
    setInvestedAmount,
    isSelectingStocks,
    setLeftSideSelectedView,
  } = useContext(UserContext);
  const navigate = useNavigate();

  const title = isSavingStocks ? "Adjust weightage" : "Add tokens";

  const subtitle = isSavingStocks
    ? "Adjust weights of each crypto"
    : // : selectedCryptos.length <= 0
      // ? "Select tokens to start tracking"
      "Select stocks to start watching";

  const handlePreviousMenu = () => {
    if (isSelectingStocks && !isSavingStocks) {
      navigate("/");
      setIsAmountInvested(false);
      // setInvestedAmount("");
      setSearchedStock("");
      setIsSelectingStocks(false);
      setSelectedCryptos([]);
      setSearchedCryptosArray([]);
      setIsSavingStocks(false);
      setLeftSideSelectedView(1);
    } else if (
      isSelectingStocks &&
      isSavingStocks &&
      selectedCryptos.length > 0
    ) {
      setIsSelectingStocks(true);
      setIsSavingStocks(false);
      setLeftSideSelectedView(1);
    } else {
      navigate("/");
      setIsAmountInvested(false);
      // setInvestedAmount("");
      setSearchedStock("");
      setIsSelectingStocks(false);
      setSelectedCryptos([]);
      setSearchedCryptosArray([]);
      setIsSavingStocks(false);
    }
  };

  return (
    <StylesAddCryptoHeaderWrapper>
      <StylesAddCryptoHeader>
        <div>
          <img src={BackArrow} alt="back" onClick={handlePreviousMenu} />
          <h2>{title}</h2>
        </div>
        <p>{subtitle}</p>
      </StylesAddCryptoHeader>
      {isSelectingStocks && (
        <div>
          <AddCryptoHeaderButton
            setIsSaveCryptoLoading={setIsSaveCryptoLoading}
            isSaveCryptoLoading={isSaveCryptoLoading}
          />
        </div>
      )}
    </StylesAddCryptoHeaderWrapper>
  );
}

function AddCryptoSearchBar({
  isSearchBarResultLoading,
  setIsSearchBarResultLoading,
}) {
  const {
    searchedStock,
    setSearchedStock,
    getSearchedCryptos,
    setIsSelectingStocks,
    setPage,
    setHasMoreFlag,
    setIsSavingStocks,
    setSelectedCryptos,
    setSearchedCryptosArray,
  } = useContext(UserContext);

  const currentPage = 1;
  const handleOnChange = async (event) => {
    setIsSearchBarResultLoading(true);
    const scrollToTop = document?.getElementById("targetDiv");
    scrollToTop?.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    try {
      setPage(currentPage);
      setSearchedStock(event.target.value);
      const cryptoData = await getSearchedCryptos(
        event.target.value,
        currentPage
      );
      cryptoData?.length < 10 && setHasMoreFlag(false);

      setIsSelectingStocks(true);
      setIsSearchBarResultLoading(false);
    } catch (error) {
      console.error(error);
      setIsSearchBarResultLoading(true);
    } finally {
      setIsSearchBarResultLoading(false);
    }
  };

  const handleBackspace = (event) => {
    if (event.key === "Backspace") {
      setHasMoreFlag(true);
    }
  };

  const fetchData = async () => {
    setIsSearchBarResultLoading(true);
    try {
      const cryptoData = await getSearchedCryptos(searchedStock, currentPage);
      cryptoData?.length < 10 && setHasMoreFlag(false);

      setIsSelectingStocks(true);
      setSearchedCryptosArray(cryptoData);
      setIsSearchBarResultLoading(false);
    } catch (error) {
      setIsSearchBarResultLoading(true);

      console.error(error);
    } finally {
      setIsSearchBarResultLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <StylesSearchBar>
      <img src={SearchIcon} alt="search" />
      <input
        type="text"
        value={searchedStock}
        onChange={handleOnChange}
        onKeyDown={handleBackspace}
        placeholder="Add a token by typing name or ticker"
        autoFocus
      />
    </StylesSearchBar>
  );
}

// function AddInvestmentAmount() {
//   const { setIsAmountInvested, investedAmount, setInvestedAmount } =
//     useContext(UserContext);
//   const [errorMessage, setErrorMessage] = useState("");
//   const handleInvestAmountOnChange = (event) => {
//     setErrorMessage("");
//     let amountEntered = event.target.value;

//     if (amountEntered.charAt(0) === ".") {
//       amountEntered = "0" + amountEntered;
//     }

//     let regex = /^\d*\.?\d{0,2}$/;
//     if (amountEntered === "" || regex.test(amountEntered)) {
//       setInvestedAmount(amountEntered);
//     } else {
//       setInvestedAmount(investedAmount);
//     }
//     return investedAmount;
//   };

//   const handleInvestAmountOnSubmit = (event) => {
//     event.preventDefault();

//     if (investedAmount === "") {
//       setErrorMessage("Enter investment amount");
//     } else if (investedAmount === "0") {
//       setErrorMessage("Investment amount cannot be 0");
//     } else if (
//       investedAmount === "0." ||
//       investedAmount === "0.0" ||
//       investedAmount === "0.00"
//     ) {
//       setErrorMessage("Investment amount should be greater than 0");
//     } else if (investedAmount.endsWith(".")) {
//       const modifiedNumber = investedAmount + "00";
//       setInvestedAmount(modifiedNumber);
//     } else {
//       setIsAmountInvested(true);
//     }
//   };

//   const handleReset = () => {
//     setInvestedAmount("");
//     setErrorMessage("");
//   };
//   return (
//     <StylesFormWrapper>
//       <form onSubmit={handleInvestAmountOnSubmit}>
//         <StylesSearchBar>
//           <img src={SearchIcon} alt="search" />
//           <input
//             type="text"
//             value={investedAmount}
//             className="input-number"
//             onChange={handleInvestAmountOnChange}
//             placeholder="Enter amount to invest"
//             autoFocus
//           />
//           <p className="error-message">{errorMessage}</p>
//         </StylesSearchBar>
//         <div className="button-wrapper">
//           <StylesButton
//             type="submit"
//             button_border={"#087AF9"}
//             button_bg_color={"#087AF9"}
//             button_text_color={"#fff"}
//             onSubmit={() => {
//               logEvent(analytics, "invest");
//             }}
//           >
//             Invest (
//             <span style={{ color: "yellow", fontWeight: 800 }}>
//               $ {investedAmount.length > 0 ? investedAmount : 0}
//             </span>
//             )
//           </StylesButton>
//           <StylesButton type="reset" onClick={handleReset}>
//             Reset
//           </StylesButton>
//         </div>
//       </form>
//     </StylesFormWrapper>
//   );
// }

export function AddCryptosHome({
  setIsCalculatedResultLoading,
  setIsETFCryptosLoading,
}) {
  const [isSearchBarResultLoading, setIsSearchBarResultLoading] =
    useState(true);
  const [isSaveCryptoLoading, setIsSaveCryptoLoading] = useState(false);
  const {
    searchedStock,
    searchedCryptosArray,
    setSearchedCryptosArray,
    getSearchedCryptos,
    page,
    setPage,
    selectedCryptos,
    setSelectedCryptos,
    isSavingStocks,
    hasMoreFlag,
    setHasMoreFlag,
    stockName,
    detectStockStatusColor,
    detectStockStatusBackgroundColor,
    roundOffPrice,
    priceChangeIn24Hours,
    setIsSavingStocks,
    addSelectedViewType,
    investedAmount,
    setCalculatedCryptosData,
    initialValues,
    leftSideSelectedView,
    exportImage,
  } = useContext(UserContext);

  useEffect(() => {
    if (selectedCryptos.length === 0) {
      setIsSavingStocks(false);
      setIsETFCryptosLoading(false);
    }
  }, [selectedCryptos]);

  useEffect(() => {
    logEvent(analytics, `${window.location.pathname}`);
  }, []);

  const fetchMoreData = async () => {
    try {
      const currentPage = page + 1;
      setPage((p) => p + 1);

      const newStocks = await getSearchedCryptos(searchedStock, currentPage);
      if (newStocks?.length === 0) {
        setHasMoreFlag(false);
        setSearchedCryptosArray(searchedCryptosArray);
      } else if (newStocks?.length > 0 && newStocks?.length < 10) {
        setSearchedCryptosArray([...searchedCryptosArray, ...newStocks]);
        setHasMoreFlag(false);
      } else {
        setSearchedCryptosArray([...searchedCryptosArray, ...newStocks]);
        setHasMoreFlag(true);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSelectedCryptos = (stock) => {
    const data = selectedCryptos?.some((val) => val?.id === stock.id);
    if (!data) {
      setSelectedCryptos((prev) => [...prev, stock]);
    } else {
      setSelectedCryptos(
        selectedCryptos?.filter((val) => val?.id !== stock.id)
      );
    }

    // setSelectedCryptos((previousSelectedCryptos) =>
    //   previousSelectedCryptos.includes(stock)
    //     ? previousSelectedCryptos.filter((data) => data !== stock)
    //     : [...previousSelectedCryptos, stock]
    // );
  };

  const addSelectedCryptosProperties = () => {
    const viewType = addSelectedViewType();

    let selectedWeightage = [];
    if (viewType === "marketcap") {
      for (let i = 0; i < selectedCryptos.length; i++) {
        selectedWeightage.push(0);
      }
    } else if (viewType === "custom") {
      for (let i = 0; i < initialValues.length; i++) {
        selectedWeightage.push(initialValues[i]?.weightage);
      }
    } else {
      for (let i = 0; i < selectedCryptos.length; i++) {
        selectedWeightage.push(
          selectedCryptos.length > 0 && 1 / selectedCryptos.length
        );
      }
    }

    const selectedCryptosData = [];

    for (let i = 0; i < selectedCryptos.length; i++) {
      selectedCryptos.length > 0 &&
        selectedCryptosData.push({
          id: selectedCryptos.length > 0 && selectedCryptos[i]?.id,
          weightage: parseFloat(selectedWeightage[i]),
          // tokensCount: Number(1),
        });
    }
    return selectedCryptosData;
  };

  const fetchCalculatedCryptos = async () => {
    setIsCalculatedResultLoading(true);
    try {
      const calculatedData = await calculateCryptos(
        addSelectedCryptosProperties(),
        addSelectedViewType(),
        Number(investedAmount)
      );
      setCalculatedCryptosData(calculatedData.data);
      setIsCalculatedResultLoading(false);
      return calculatedData.data;
    } catch (error) {
      console.error(error);
      setIsCalculatedResultLoading(true);
    } finally {
      setIsCalculatedResultLoading(false);
    }
  };

  useEffect(() => {
    if (selectedCryptos.length > 1) {
      fetchCalculatedCryptos();
    }
  }, [selectedCryptos, leftSideSelectedView]);
  return (
    <StylesApp>
      <AddCryptoHeader
        setIsSaveCryptoLoading={setIsSaveCryptoLoading}
        isSaveCryptoLoading={isSaveCryptoLoading}
      />
      {!isSavingStocks || selectedCryptos.length === 0 ? (
        <>
          <AddCryptoSearchBar
            isSearchBarResultLoading={isSearchBarResultLoading}
            setIsSearchBarResultLoading={setIsSearchBarResultLoading}
          />
          <div className="stock-list-block" id="targetDiv">
            {isSearchBarResultLoading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignContent: "center",
                }}
              >
                <Loader height={"25px"} width={"25px"} />
                <span style={{ paddingLeft: "5px" }}>Loading..</span>
              </div>
            ) : (
              <InfiniteScroll
                dataLength={searchedCryptosArray.length}
                next={fetchMoreData}
                hasMore={hasMoreFlag}
                loader={
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignContent: "center",
                    }}
                  >
                    <Loader height={"15px"} width={"15px"} />
                    <span style={{ paddingLeft: "5px" }}>Loading..</span>
                  </div>
                }
                endMessage={
                  searchedCryptosArray.length > 0 && (
                    <p
                      style={{
                        textAlign: "center",
                        color: "#878787",
                        fontSize: "12px",
                      }}
                    >
                      <b>Yay! You have seen it all</b>
                    </p>
                  )
                }
                scrollableTarget="targetDiv"
              >
                {searchedCryptosArray?.length > 0 ? (
                  searchedCryptosArray?.map((data, uniqueData) => {
                    return (
                      <StylesStocksList
                        key={uniqueData}
                        onClick={() => handleSelectedCryptos(data)}
                        style={{
                          backgroundColor: selectedCryptos.some(
                            (val) => val?.id === data?.id
                          )
                            ? "#087AF91A"
                            : "transparent",
                        }}
                      >
                        <StylesStocksListLeftSection>
                          <img src={data?.logo} alt="profile" />
                          <span
                            className="stock-name"
                            data-tooltip-id="stock-name-tooltip"
                            data-tooltip-content={data?.cryptoName}
                          >
                            {stockName(data?.cryptoName)}
                          </span>
                          <i>&#8226;</i>
                          <span className="stock-suffix">{data?.symbol}</span>
                        </StylesStocksListLeftSection>
                        <StylesStocksListRightSection
                          statusColor={detectStockStatusColor(
                            Number(data?.percent_change_24h)
                          )}
                          statusBackgroundColor={detectStockStatusBackgroundColor(
                            Number(data?.percent_change_24h)
                          )}
                        >
                          <span className="stock-price">
                            ${roundOffPrice(Number(data?.currentPrice))}
                          </span>
                          <div>
                            <span className="stock-status">
                              {exportImage(data?.percent_change_24h)}$
                              {Math.abs(
                                roundOffPrice(
                                  priceChangeIn24Hours(
                                    Number(data?.currentPrice),
                                    Number(data?.percent_change_24h)
                                  )
                                )
                              )}
                              &nbsp;(
                              {Math.abs(
                                roundOffPrice(Number(data?.percent_change_24h))
                              )}
                              %)
                            </span>
                          </div>
                        </StylesStocksListRightSection>
                        <Tooltip id="stock-name-tooltip" />
                      </StylesStocksList>
                    );
                  })
                ) : (
                  <p
                    style={{
                      textAlign: "center",
                      color: "#878787",
                    }}
                  >
                    {!isSearchBarResultLoading && <b>No data exists</b>}
                  </p>
                )}
              </InfiniteScroll>
            )}
          </div>
        </>
      ) : (
        <div>
          <SaveCrypto fetchCalculatedCryptos={fetchCalculatedCryptos} />
        </div>
      )}
    </StylesApp>
  );
}

export const SaveCrypto = ({ fetchCalculatedCryptos }) => {
  const {
    selectedCryptos,
    content,
    setContent,
    leftSideSelectedView,
    setLeftSideSelectedView,
    rightSideSelectedView,
    setRightSideSelectedView,
    stockName,
    contentError,
    initialValues,
    setInitialValues,
    calculatedCryptosData,
  } = useContext(UserContext);
  const [editable, setEditable] = useState(false);

  const handleContentChange = (event) => {
    setContent(event.target.value);
  };

  const handleEditClick = () => {
    setEditable(true);
  };

  const handleBlur = () => {
    setEditable(false);
  };

  const styles = {
    border: "1px solid #404040",
    padding: "5px 10px",
    backgroundColor: "#1f1f1f",
    borderRadius: "5px",
    width: "70%",
    outline: "none",
    userSelect: "text",
    color: "white",
  };

  const handleLeftSideButtonClick = (buttonIndex) => {
    setLeftSideSelectedView(buttonIndex);
  };

  const handleRightSideButtonClick = (buttonIndex) => {
    setRightSideSelectedView(buttonIndex);
  };

  const View = () => {
    return (
      <StylesViewWrapper>
        <div>
          <h5>Choose view</h5>
          <div className="choose-view">
            <div
              className="left-div"
              onClick={() => handleLeftSideButtonClick(1)}
              style={{
                backgroundColor:
                  leftSideSelectedView === 1 ? "#087AF9" : "transparent",
                borderTopLeftRadius: "15px",
                borderBottomLeftRadius: "15px",
              }}
            >
              Equal
            </div>
            <div
              className="left-div divider-span"
              onClick={() => handleLeftSideButtonClick(2)}
              style={{
                backgroundColor:
                  leftSideSelectedView === 2 ? "#087AF9" : "transparent",
              }}
            >
              Market Cap
            </div>
            <div
              className="left-div"
              onClick={() => {
                handleLeftSideButtonClick(3);
                logEvent(analytics, "custom_Weightage");
              }}
              style={{
                backgroundColor:
                  leftSideSelectedView === 3 ? "#087AF9" : "transparent",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              Custom
            </div>
          </div>
        </div>
        <div>
          <h5>weightage scheme</h5>

          <div style={{ textAlign: "center" }}>
            <div
              className="right-div"
              onClick={() => handleRightSideButtonClick(1)}
              style={{
                backgroundColor:
                  rightSideSelectedView === 1 ? "#087AF9" : "transparent",
                borderRadius: "15px",
                // borderTopLeftRadius: "15px",
                // borderBottomLeftRadius: "15px",
              }}
            >
              Weightage
            </div>
            {/* <div
              className="right-div divider-span-2"
              onClick={() => handleRightSideButtonClick(2)}
              style={{
                backgroundColor:
                  rightSideSelectedView === 2 ? "#087AF9" : "transparent",
                borderTopRightRadius: "15px",
                borderBottomRightRadius: "15px",
              }}
            >
              Tokens
            </div> */}
          </div>
        </div>
      </StylesViewWrapper>
    );
  };

  const eachValuesRequired = () => {
    const newArray = selectedCryptos.map((data) => {
      return {
        ...data,
        lock: false,
        weightage: 1 / selectedCryptos.length,
      };
    });
    setInitialValues(newArray);
  };

  const helperFunction = async (array, id, action, amount) => {
    const index = array.findIndex((item) => item.id === id);
    array[index].lock = true;
    const allItemsLocked = array.every((item, i) => {
      if (i !== index) {
        return item.lock === true;
      }
      // return item.lock === true;
    });

    if (allItemsLocked) {
      array.forEach((item) => {
        item.lock = false;
      });
    }
    let weightage = parseFloat(array[index].weightage);
    if (action === "add") {
      weightage += 0.05;
      let addWeightage = [...initialValues];
      addWeightage[index].weightage = addWeightage[index].weightage + 0.05;
      setInitialValues(addWeightage);
    } else if (action === "subtract") {
      weightage -= 0.05;
      let subtractWeightage = [...initialValues];
      subtractWeightage[index].weightage =
        subtractWeightage[index].weightage - 0.05;
      setInitialValues(subtractWeightage);
    } else if (action === "added") {
      weightage = amount;
      let addedWeightage = [...initialValues];
      addedWeightage[index].weightage =
        addedWeightage[index].weightage + amount;

      setInitialValues(addedWeightage);
    } else if (action === "subtracted") {
      weightage = amount;
      let subtractedWeightage = [...initialValues];
      subtractedWeightage[index].weightage =
        subtractedWeightage[index].weightage - amount;
      setInitialValues(subtractedWeightage);
    } else {
      console.error("Invalid action");
      setInitialValues(initialValues);
      weightage = weightage;
    }

    weightage = Math.max(0.05, Math.min(0.95, parseFloat(weightage)));
    // if (weightage >= 1) {
    //   weightage = "0.95";
    // } else if (weightage <= 0) {
    //   // if (weightage.startsWith("0.")) {
    //   weightage = "0.5";
    //   // }
    //   // weightage = "0";
    // } else {
    //   weightage = weightage;
    // }
    array[index].weightage = weightage.toString();
    array[index].lock = true;

    const totalWeightage = array.reduce(
      (total, item) => total + parseFloat(item?.weightage),
      0
    );
    // if (totalWeightage > 1) {
    //   alert("max is 1");
    //   return;
    // } else if (totalWeightage === 1) {
    //   alert("sum is 1");
    // }
    const unlockedData = array.filter(
      (res, i) => i !== index && res.lock
    ).length;

    const adjustment = (1 - totalWeightage) / (array.length - 1 - unlockedData);
    array.forEach((item, i) => {
      // let itemWeightage = ParseFloat(item?.weightage);
      if (i !== index && !array[i].lock) {
        item.weightage = parseFloat(item?.weightage) + adjustment;
      }
    });
    await fetchCalculatedCryptos();

    return array;
  };

  useEffect(() => {
    eachValuesRequired();
  }, [selectedCryptos]);
  return (
    <StylesSaveCrypto>
      <div className="stock-group-header">
        {editable ? (
          <input
            onChange={handleContentChange}
            onBlur={handleBlur}
            style={styles}
            dir="ltr"
            value={content}
            type="text"
            autoFocus
          />
        ) : (
          <h1>{content}</h1>
        )}
        <p className="error-message">{content.length <= 0 && contentError}</p>

        {editable ? (
          <span style={{ cursor: "pointer" }}>Save</span>
        ) : (
          <img
            src={StockGroup}
            alt="icon"
            onClick={() => {
              handleEditClick();
              logEvent(analytics, "edit_ETF_name");
            }}
          />
        )}
      </div>
      <View />

      <div className="selected-stocks">
        {selectedCryptos.length > 0
          ? selectedCryptos.map((crypto, uniqueCrypto) => {
              const marketCapWeightage = () => {
                if (leftSideSelectedView === 2) {
                  selectedCryptos.map((res, i) => {
                    res.weightage =
                      calculatedCryptosData?.selectedCryptoData[i]?.weightage ||
                      0;
                  });
                }
              };

              marketCapWeightage();
              const roundOffNumber = () => {
                if (leftSideSelectedView === 1) {
                  return 100 / selectedCryptos.length;
                }
              };

              const handleInputOnChange = (event, index) => {
                // let amountEntered = event.target.value;
                let curValue = event.target.value;
                let prevValue = initialValues[index]?.weightage.toString();
                curValue = curValue.trim();

                console.log(typeof prevValue, typeof curValue);

                const updatedWeightageValues = [...initialValues];

                if (curValue !== "") {
                  curValue = curValue.replace(/[^\d.]/g, "");
                }

                if (prevValue === "") {
                  prevValue = "0";
                  // initialValues[index].weightage = "0";
                }

                if (curValue === "") {
                  curValue = "0";
                }

                if (
                  curValue.length > 1 &&
                  curValue.startsWith("0") &&
                  !curValue.startsWith("0.")
                ) {
                  curValue = curValue.replace(/^0+/, "");
                }

                if (
                  curValue.includes(".") &&
                  curValue.indexOf(".") !== curValue.lastIndexOf(".")
                ) {
                  let index = curValue.lastIndexOf(".");
                  curValue =
                    curValue.substring(0, index) +
                    curValue.substring(index + 1);
                }

                updatedWeightageValues[index].weightage = curValue;

                // if (Number(curValue) <= Number(prevValue)) {
                setInitialValues(updatedWeightageValues);
                //   console.log("add");
                helperFunction(
                  initialValues,
                  initialValues[index]?.id,
                  "added",
                  curValue
                );
                // } else {
                //   setInitialValues(updatedWeightageValues);
                //   console.log("subtract");
                //   helperFunction(
                //     initialValues,
                //     initialValues[index]?.id,
                //     "subtracted",
                //     curValue
                //   );
                // }
              };

              return (
                <StylesSelectedSaveCryptos key={uniqueCrypto}>
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <img src={DragImage} alt="image" />
                    <img
                      src={crypto?.logo}
                      alt="logo"
                      className="profile-image"
                    />
                    <span
                      className="stock-name"
                      data-tooltip-id="stock-name-tooltip"
                      data-tooltip-content={crypto?.cryptoName}
                    >
                      {stockName(crypto.cryptoName)}
                    </span>
                    <i>&#8226;</i>
                    <span className="stock-suffix">{crypto.symbol}</span>
                  </div>
                  <StylesSaveCryptosRightSide>
                    {leftSideSelectedView === 1 && (
                      <>
                        <div
                          className="stocks-percentage"
                          data-tooltip-id="equal-type-tooltip"
                          data-tooltip-content={roundOffNumber()}
                        >
                          {roundOffNumber()}
                        </div>
                      </>
                    )}
                    {leftSideSelectedView === 2 && (
                      <>
                        <div
                          className="stocks-percentage"
                          data-tooltip-id="market-cap-type-tooltip"
                          data-tooltip-content={
                            (crypto.weightage * 100)?.toFixed(5) || 0
                          }
                        >
                          {(crypto.weightage * 100)?.toFixed(5) || 0}
                        </div>
                      </>
                    )}
                    {leftSideSelectedView === 3 && (
                      <>
                        <span
                          onClick={() =>
                            helperFunction(
                              initialValues,
                              initialValues[uniqueCrypto].id,
                              "subtract"
                            )
                          }
                        >
                          -
                        </span>
                        <input
                          type="text"
                          className="stocks-percentage"
                          // valu(e={`${initialValues[uniqueCrypto]?.weightage}`}
                          value={initialValues[uniqueCrypto]?.weightage * 100}
                          onChange={(e) => handleInputOnChange(e, uniqueCrypto)}
                          disabled
                          data-tooltip-id="custom-type-tooltip"
                          data-tooltip-content={
                            initialValues[uniqueCrypto]?.weightage * 100
                          }
                        />
                        <span
                          onClick={() =>
                            helperFunction(
                              initialValues,
                              initialValues[uniqueCrypto].id,
                              "add"
                            )
                          }
                        >
                          +
                        </span>
                      </>
                    )}
                  </StylesSaveCryptosRightSide>
                  <Tooltip id="equal-type-tooltip" />
                  <Tooltip id="custom-type-tooltip" />
                  <Tooltip id="market-cap-type-tooltip" />
                  <Tooltip id="stock-name-tooltip" />
                </StylesSelectedSaveCryptos>
              );
            })
          : "No data exists"}
      </div>
    </StylesSaveCrypto>
  );
};

export default function Dashboard({
  setIsETFCryptosLoading,
  setIsCalculatedResultLoading,
}) {
  const [userETFs, setUserETFs] = useState([]);
  const [stocksId, setStocksId] = useState("0");
  const [isETFloading, setIsETFLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [popUp, setPopUp] = useState(false);
  const [stockIndex, setStockIndex] = useState(0);

  const {
    setSelectedCryptos,
    setSearchedStock,
    setIsSelectingStocks,
    setIsSavingStocks,
    exportImage,
    setIsAmountInvested,
    roundOffPrice,
    setCalculatedCryptosData,
  } = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    logEvent(analytics, `${window.location.pathname}`);
  }, []);

  const addNewETF = () => {
    setIsSavingStocks(false);
    setIsSelectingStocks(false);
    setSelectedCryptos([]);
    setSearchedStock("");
    setIsAmountInvested(false);
    navigate("/add-crypto");
    setIsETFCryptosLoading(false);
    setIsCalculatedResultLoading(false);
  };

  const fetchUserETFs = async () => {
    setIsETFLoading(true);
    try {
      const ETFs = await getUserETFs();
      setUserETFs(ETFs?.data);
      setStocksId(ETFs?.data[0]?.id);
      setIsETFLoading(false);

      return ETFs?.data;
    } catch (error) {
      setIsETFLoading(true);

      console.error(error);
    } finally {
      setIsETFLoading(false);
    }
  };

  async function getUserCryptos(etfId) {
    setIsETFCryptosLoading(true);
    setIsCalculatedResultLoading(true);
    try {
      const cryptos = await getUserETFsCryptos(etfId);
      setSelectedCryptos(cryptos?.data?.etfCryptoData);
      setCalculatedCryptosData(cryptos?.data?.calculateCrypto);
      setIsETFCryptosLoading(false);
      setIsCalculatedResultLoading(false);

      return cryptos?.data;
    } catch (error) {
      console.error(error);
      setIsCalculatedResultLoading(true);
      setIsETFCryptosLoading(true);
    } finally {
      setIsCalculatedResultLoading(false);
      setIsETFCryptosLoading(false);
    }
  }

  function stockName(name) {
    let modifiedName;
    if (name.length > 35) {
      modifiedName = name.slice(0, 34) + "..";
    } else {
      modifiedName = name;
    }
    return modifiedName;
  }

  useEffect(() => {
    fetchUserETFs();
  }, []);

  useEffect(() => {
    if (stocksId !== "0") {
      getUserCryptos(stocksId);
    }
  }, [stocksId]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setPopUp(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [popUp]);
  return (
    <StylesApp>
      <StylesAddCryptoHeaderWrapper>
        <StylesAddCryptoHeader>
          <div>
            <h2>My Idea’s</h2>
          </div>
          <p>Select tokens to start watching</p>
        </StylesAddCryptoHeader>
        <StylesButton
          button_border="#087AF9"
          button_bg_color="#087AF9"
          button_text_color="#fff"
          onClick={() => {
            addNewETF();
            logEvent(analytics, "add_new_Etf");
          }}
        >
          <img src={AddIcon} alt="+" style={{ marginRight: "5px" }} />
          <span>Add new Idea</span>
        </StylesButton>
      </StylesAddCryptoHeaderWrapper>
      <StylesSavedStocksList>
        {isETFloading ? (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignContent: "center",
            }}
          >
            <Loader height={"20px"} width={"20px"} />
            <span style={{ paddingLeft: "5px" }}>Loading..</span>
          </div>
        ) : userETFs ? (
          userETFs?.map((stock, uniqueStocks) => {
            function detectStockStatusColor(value) {
              if (value < 0) {
                return "#EE0000";
              } else {
                return "#18AB16";
              }
            }

            const handleClick = (id) => {
              setStocksId(id);
            };
            const styles = {
              listItemClicked: {
                border: "2px solid #087AF9",
              },
              listItem: {
                border: "1px solid #313131",
              },
            };

            const handlePopUpModel = (event, id, uniqueStocks) => {
              event.stopPropagation();

              if (id === stocksId) {
                setShowModal(true);
                setStockIndex(uniqueStocks);
              }
            };

            return (
              <StylesSavedStocks
                key={uniqueStocks}
                today_usd_status={detectStockStatusColor(
                  stock?.calculation?.totalGainOrLossPercentageUSD_24
                )}
                total_usd_status={detectStockStatusColor(
                  stock?.calculation?.totalGainOrLossPercentageUSD_90
                )}
                today_eth_status={detectStockStatusColor(
                  stock?.calculation?.totalGainOrLossPercentageETH_24
                )}
                total_eth_status={detectStockStatusColor(
                  stock?.calculation?.totalGainOrLossPercentageETH_90
                )}
                style={
                  stock?.id === stocksId
                    ? styles.listItemClicked
                    : styles.listItem
                }
                onClick={(e) => {
                  handleClick(stock?.id);
                  logEvent(analytics, "my_etf");
                }}
                border={stock?.id === stocksId && "#087AF9"}
                bg_color={stock?.id === stocksId && "#10101050"}
              >
                <h2
                  className="left"
                  data-tooltip-id="etf-name-tooltip"
                  data-tooltip-content={stock?.name}
                >
                  {stockName(stock?.name)}

                  <span
                    onClick={(e) =>
                      handlePopUpModel(e, stock?.id, uniqueStocks)
                    }
                    className="popUpWrapper"
                  >
                    <img src={ShareIcon} alt="share" className="share" />
                  </span>
                </h2>

                <div className="right">
                  <div className="today-header">
                    <span>Today</span>
                    <span>Eth</span>
                  </div>
                  <div className="today-value">
                    <span className="today-usd-value">
                      {exportImage(
                        stock?.calculation?.totalGainOrLossPercentageUSD_24
                      )}
                      {roundOffPrice(
                        stock?.calculation?.totalGainOrLossPercentageUSD_24
                      )}
                      %
                    </span>
                    <span className="today-eth-value">
                      {exportImage(
                        stock?.calculation?.totalGainOrLossPercentageETH_24
                      )}
                      {roundOffPrice(
                        stock?.calculation?.totalGainOrLossPercentageETH_24
                      )}
                      %
                    </span>
                  </div>
                  <div className="total-header ">
                    <span>Total</span>
                    <span>Eth</span>
                  </div>
                  <div className="total-value">
                    <span className="total-usd-value">
                      {exportImage(
                        stock?.calculation?.totalGainOrLossPercentageUSD_90
                      )}
                      {roundOffPrice(
                        stock?.calculation?.totalGainOrLossPercentageUSD_90
                      )}
                      %
                    </span>
                    <span className="total-eth-value">
                      {exportImage(
                        stock?.calculation?.totalGainOrLossPercentageETH_90
                      )}
                      {roundOffPrice(
                        stock?.calculation?.totalGainOrLossPercentageETH_90
                      )}
                      %
                    </span>
                  </div>
                </div>
                <Tooltip id="etf-name-tooltip" />
              </StylesSavedStocks>
            );
          })
        ) : (
          navigate("/add-crypto")
        )}
        <StylesAddNewStocks
          onClick={() => {
            addNewETF();
            logEvent(analytics, "add_new_Etf");
          }}
        >
          + Add New Idea
        </StylesAddNewStocks>
      </StylesSavedStocksList>

      {showModal && (
        <Share
          setShowModal={setShowModal}
          setPopUp={setPopUp}
          popUp={popUp}
          userETFs={userETFs}
          stockIndex={stockIndex}
          stocksId={stocksId}
        />
      )}
      {popUp && <PopUp> link copied successfully</PopUp>}
    </StylesApp>
  );
}
