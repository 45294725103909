import { createContext, useState } from "react";
import { searchCryptoApi } from "../../services/api";
import UpArrow from "../../assets/images/up-arrow.svg";
import DownArrow from "../../assets/images/down-arrow.svg";
export const UserContext = createContext(null);

function Context({ children }) {
  const [searchedStock, setSearchedStock] = useState("");
  const [searchedCryptosArray, setSearchedCryptosArray] = useState([]);
  const [page, setPage] = useState(2);
  const [hasMoreFlag, setHasMoreFlag] = useState(true);
  const [selectedCryptos, setSelectedCryptos] = useState([]);
  const [isSavingStocks, setIsSavingStocks] = useState(false);
  const [isAmountInvested, setIsAmountInvested] = useState(false);
  const [investedAmount, setInvestedAmount] = useState(100);
  const [isSelectingStocks, setIsSelectingStocks] = useState(false);
  const [content, setContent] = useState("My Idea 1");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [leftSideSelectedView, setLeftSideSelectedView] = useState(1);
  const [rightSideSelectedView, setRightSideSelectedView] = useState(1);
  const [walletAddress, setWalletAddress] = useState("");
  const [calculatedCryptosData, setCalculatedCryptosData] = useState({});
  const [contentError, setContentError] = useState("");
  const [initialValues, setInitialValues] = useState([]);

  const getSearchedCryptos = async (name, currentPage) => {
    const fetchedCryptos = await searchCryptoApi(name, currentPage);
    setSearchedCryptosArray(fetchedCryptos?.data);
    return fetchedCryptos?.data;
  };

  function stockName(name) {
    let modifiedName;
    if (name.length > 12) {
      modifiedName = name.slice(0, 11) + "..";
    } else {
      modifiedName = name;
    }
    return modifiedName;
  }
  function detectStockStatusColor(changeOfPrice) {
    if (changeOfPrice >= 0) {
      return "#18AB16";
    } else {
      return "#EE0000";
    }
  }
  function detectStockStatusBackgroundColor(changeOfPrice) {
    if (changeOfPrice >= 0) {
      return "#04bb001a";
    } else {
      return "#ee00001a";
    }
  }
  function roundOffPrice(price) {
    return Math.abs(price.toFixed(2));
  }

  function priceChangeIn24Hours(price, percentage) {
    let finalPriceChange;
    let priceChange = (price + Math.abs(percentage)) / 100;
    if (percentage >= 0) {
      finalPriceChange = price - priceChange;
    } else {
      finalPriceChange = price + priceChange;
    }

    return finalPriceChange;
  }

  const addSelectedViewType = () => {
    if (leftSideSelectedView === 1) {
      return "equal";
    } else if (leftSideSelectedView === 2) {
      return "marketcap";
    } else if (leftSideSelectedView === 3) {
      return "custom";
    }
  };

  const addSelectedScheme = () => {
    if (rightSideSelectedView === 1) {
      return "weightage";
    }
    // else if (rightSideSelectedView === 2) {
    //   return "tokens";
    // }
  };

  const exportImage = (parameter, height, width) => {
    if (parameter >= 0) {
      return (
        <img src={UpArrow} alt="+" height={height} width={width} load="lazy" />
      );
    } else {
      return (
        <img
          src={DownArrow}
          alt="-"
          height={height}
          width={width}
          load="lazy"
        />
      );
    }
  };

  return (
    <UserContext.Provider
      value={{
        searchedStock,
        setSearchedStock,
        searchedCryptosArray,
        setSearchedCryptosArray,
        page,
        setPage,
        getSearchedCryptos,
        selectedCryptos,
        setSelectedCryptos,
        isSavingStocks,
        setIsSavingStocks,
        isSelectingStocks,
        setIsSelectingStocks,
        content,
        setContent,
        hasMoreFlag,
        setHasMoreFlag,
        stockName,
        detectStockStatusColor,
        detectStockStatusBackgroundColor,
        roundOffPrice,
        priceChangeIn24Hours,
        isLoggedIn,
        setIsLoggedIn,
        leftSideSelectedView,
        setLeftSideSelectedView,
        rightSideSelectedView,
        setRightSideSelectedView,
        walletAddress,
        setWalletAddress,
        addSelectedViewType,
        addSelectedScheme,
        isAmountInvested,
        setIsAmountInvested,
        investedAmount,
        setInvestedAmount,
        calculatedCryptosData,
        setCalculatedCryptosData,
        contentError,
        setContentError,
        initialValues,
        setInitialValues,
        exportImage,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export default Context;
