import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";

export const firebaseConfig = {
  apiKey: "AIzaSyAR3Fcp190n8L-gs8RHnB0amHdTkjUiV2k",
  authDomain: "tracker-b67ca.firebaseapp.com",
  projectId: "tracker-b67ca",
  storageBucket: "tracker-b67ca.appspot.com",
  messagingSenderId: "762099974000",
  appId: "1:762099974000:web:8bf59faec8743b661f99b5",
  measurementId: "G-HKDV8WJTKW",
};

const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
