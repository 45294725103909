import styled from "styled-components";

export const StylesCryptoHome = styled.div`
  width: 100%;
  max-width: 900px;
  display: flex;
  margin: 0 auto;
  padding: 30px;
  gap: 15px;
  /* height: 65vh; */

  .add-cryptos-home {
    width: 60%;
  }

  .added-cryptos-home {
    width: 40%;
  }
`;

export const StylesHeaderWrapper = styled.div``;
