import tracker from "../../assets/images/addedCryptos/trackerLogo.svg";
import downArrow from "../../assets/images/addedCryptos/downArrow.svg";
import { CommonWrapper } from "../../components/AddedCryptos/styles";
import { useNavigate } from "react-router-dom";
import ConnectWithUs from "../../assets/images/header/connect-with-us.svg";

export const Footer = () => {
  const navigate = useNavigate();

  const handleConnectWithUsButton = () => {
    window.open(process.env.REACT_APP_TWITTER_LINK);
  };

  return (
    <div>
      <CommonWrapper
        className="learnMoreWrap"
        onClick={handleConnectWithUsButton}
      >
        <p className="learnMore">Learn more about Tracker</p>
        <img className="downArrow" src={downArrow} alt="downArrow" />
      </CommonWrapper>
      <footer>
        <CommonWrapper className="wrapper">
          <div className="tracker">
            <img
              src={tracker}
              alt="tracker"
              onClick={() => {
                navigate("/");
              }}
            />
          </div>
        </CommonWrapper>
        <p className="trackerMessage">
          Invest with confidence, track with clarity.
        </p>
        <CommonWrapper gap="40px">
          {/* <p className="helper">About Us</p>
          <p className="helper">Contact Us</p>
          <p className="helper">Terms & Privacy</p> */}
          <div style={{ textAlign: "center", marginTop: "15px" }}>
            <div className="faq" onClick={handleConnectWithUsButton}>
              <img src={ConnectWithUs} alt="icon" className="faq-icon" />
              <span>Connect with us</span>
            </div>
            {/* <p className="helper">Terms & Privacy</p> */}
          </div>
        </CommonWrapper>
      </footer>
    </div>
  );
};
