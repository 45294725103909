import React, { useContext, useEffect, useState } from "react";
import {
  ShareContainer,
  Navigators,
  ProfileWrap,
  MessageBox,
  PopUp,
} from "./styles";
import { CommonWrapper } from "../AddedCryptos/styles";
import Profile from "../../assets/images/addedCryptos/profile.png";
import ShareFar from "../../assets/images/addedCryptos/share.svg";
import { UserContext } from "../context/ContextApi";
import Frames from "../Frame";

const Share = ({ setShowModal, setPopUp, stockIndex, userETFs, stocksId }) => {
  const { walletAddress } = useContext(UserContext);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(
      `https://revise-tracker-frames.vercel.app/api/${stocksId}`
    );
    setPopUp(true);
    setShowModal(false);
  };

  return (
    <ShareContainer
      onClick={() => {
        setShowModal(false);
      }}
    >
      <div
        className="container"
        onClick={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
      >
        <Navigators>
          <CommonWrapper jc="flex-start">
            <img src={ShareFar} alt="farcaster" />
            <p>Share on Farcaster</p>
          </CommonWrapper>
        </Navigators>
        <ProfileWrap>
          <CommonWrapper ai="flex-start">
            <div className="leftSection">
              <CommonWrapper jc="flex-start" className="profileWrapper">
                <img src={Profile} alt="profile" />
                <p className="profile">
                  {" "}
                  <span>
                    {walletAddress.slice(0, 6) +
                      "...." +
                      walletAddress.slice(-4)}
                  </span>
                </p>
              </CommonWrapper>
              <MessageBox>
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                  Mauris volutpat viverra augue id congue. Aliquam ultricies ac
                  neque eget tempus. Proin vitae ipsum ac lorem semper varius.
                  Donec malesuada mattis dolor vitae elementum.{" "}
                </p>
              </MessageBox>
            </div>

            <div
              className="rightSection"
              style={{
                width: "450px",
                height: "350px",
                background: "linear-gradient(150deg, #103158, #190509)",
                borderRadius: "15px",
                padding: "10px ",
              }}
            >
              <Frames stockIndex={stockIndex} userETFs={userETFs} />
            </div>
          </CommonWrapper>
        </ProfileWrap>
        <Navigators>
          <CommonWrapper jc="flex-end">
            <button className="cancel" onClick={() => setShowModal(false)}>
              Cancel
            </button>
            <button onClick={handleCopyLink}> Copy Link</button>
          </CommonWrapper>
        </Navigators>
      </div>
    </ShareContainer>
  );
};

export default Share;
