import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import App from "./App";
import Context from "./components/context/ContextApi";
import { Helmet } from "react-helmet";
import FavIcon from "./assets/images/favIcon/favicon-16x16.png";
import "./index.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Context>
    <Helmet>
      <meta charSet="utf-8" />
      <title>FanSwap</title>
      <meta
        name="description"
        content="Invest with confidence, track with clarity."
      />
      <link rel="icon" type="image/x-icon" href={FavIcon} />
    </Helmet>
    <App />
  </Context>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
