import styled from "styled-components";
import investBg from "../../assets/images/add-cryptos/invest.png";

export const StartInvestWrap = styled.div`
  height: 65vh;
  padding: 30px;
`;

export const StartInvest = styled.div`
  width: 540px;
  height: 50vh;
  background-color: #087af9;
  background-image: url(${investBg});
  background-size: 34vh;
  background-repeat: no-repeat;
  background-position: bottom;

  border-radius: 15px;
  padding: 40px;
  margin: 15px auto 0;

  h2 {
    font-size: 28px;
    font-weight: 700;
    line-height: 43.2px;
    letter-spacing: 0.01em;
    text-align: center;
    color: #fff;
  }
  .createETF {
    font-size: 16px;
    width: fit-content;
    margin: 16px auto;
    font-weight: 700;
    color: #000;
    padding: 16px 20px;
    border-radius: 15px;
    background: linear-gradient(90deg, #ffd333 0%, #ffd333 100%);
    cursor: pointer;
  }
`;
export const StartInvestHeader = styled.div`
  width: 540px;
  margin: 0 auto;

  .customETF {
    p {
      font-size: 20px;
      font-weight: 700;
      line-height: 26.4px;
      color: #fff;
    }
    span {
      font-size: 12px;
      font-weight: 500;
      color: #878787;
    }
  }
  .profileWrap {
    padding: 5px 10px;
    border: 2px solid #3b3939;
    border-radius: 35px;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    line-height: 22px;

    .rightArrow {
      width: 8px;
      height: 15px;
    }
  }
`;
