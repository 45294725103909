import React, { useContext, useEffect, Suspense, useState } from "react";
import CreateETF from "./components/CreateETF";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import PageNotFound from "./components/PageNotFound";
import { StylesHeaderWrapper } from "./containers/cryptos/styles";
import Header from "./components/Header";
import Stocks from "./containers/cryptos";
import { Footer } from "./components/Footer";
import { UserContext } from "./components/context/ContextApi";
import ProtectedRoute from "./components/ProtectedRoute";
import { logEvent } from "firebase/analytics";
import { analytics } from "./firebaseConfig";
import Loader from "./components/Loader";
import "./App.css";

const PageLoader = ({ children, loading }) => {
  return <>{loading ? <Loader /> : children}</>;
};

const App = () => {
  const [isLoading, setIsLoading] = useState(true);

  const { isLoggedIn, setIsLoggedIn, setWalletAddress } =
    useContext(UserContext);

  useEffect(() => {
    logEvent(analytics, "landing_page");
  }, []);

  async function getAccount() {
    try {
      if (window.ethereum) {
        const accounts = await window?.ethereum
          .request({ method: "eth_requestAccounts" })
          .catch((err) => {
            if (err.code === 4001) {
              alert("Please connect to MetaMask.");
            } else {
              console.error(err);
            }
          });
        const account = accounts[0];
        setWalletAddress(account);
      }
    } catch (error) {
      console.log(error);
    }
  }

  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token !== null && token !== "") {
      setIsLoggedIn(true);
      getAccount();
    } else {
      setIsLoggedIn(false);
      setWalletAddress("");
      localStorage.clear();
    }
  }, [token, isLoggedIn]);

  const handlePageLoader = () => {
    setIsLoading(false);
  };

  useEffect(() => {
    handlePageLoader();
  }, []);

  return (
    <Router>
      {/* <Share /> */}
      <PageLoader loading={isLoading}>
        <Routes>
          <Route element={<ProtectedRoute />}>
            <Route
              path="/add-crypto"
              element={
                <div className="wrapper_container">
                  <StylesHeaderWrapper>
                    <Header />
                  </StylesHeaderWrapper>
                  <Stocks />
                  <Footer />
                </div>
              }
            />
            <Route
              path="/dashboard"
              element={
                <div className="wrapper_container">
                  <StylesHeaderWrapper>
                    <Header />
                  </StylesHeaderWrapper>
                  <Stocks />
                  <Footer />
                </div>
              }
            />
          </Route>
          <Route
            path="/"
            element={
              <div className="wrapper_container">
                <StylesHeaderWrapper>
                  <Header />
                </StylesHeaderWrapper>
                <CreateETF />
                <Footer />
              </div>
            }
          />

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </PageLoader>
    </Router>
  );
};

export default App;
