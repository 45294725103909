import styled from "styled-components";

export const StylesLoader = styled.div`
  .loader-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 10px;
  }

  .loader {
    border: 4px solid rgb(8, 122, 249);
    border-top: 5px solid #f3f3f3;
    border-radius: 50%;
    width: ${(props) => props.width || "40px"};
    height: ${(props) => props.height || "40px"};
    animation: spin 2s linear infinite;
    margin-bottom: 10px;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
