import styled from "styled-components";

export const StylesHeader = styled.div`
  display: flex;
  justify-content: space-between;

  .faq {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #000000;
    padding: 3px 10px;
    border-radius: 35px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    border: 1px solid #2a2a2a;
    width: 155px;

    &:hover {
      opacity: 0.8;
    }

    .faq-icon {
      height: 14px;
      width: 14px;
    }

    span {
      padding: 0 5px;
    }
  }

  .logo {
    height: 22px;
    cursor: pointer;
  }

  .wallet-address {
    width: 155px;
    /* height: 30px; */
    cursor: pointer;
    border-radius: 35px;
    border: 1px solid ${(props) => props.brd_color || "#2a2a2a"};
    background-color: ${(props) => props.bg_color || "#000"};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px 10px;
    font-size: 14px;
    font-weight: 500;

    span {
      padding: 0 10px;
    }

    .display-profile {
      height: 14px;
      width: 14px;
    }
  }

  .wallet {
    height: 14px;
    width: 14px;
  }
  .button-text {
    margin: 0 5px;
  }
`;
