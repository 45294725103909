import React, { useContext, useEffect, useState } from "react";
import { StylesHeader } from "./styles";
import ConnectWithUs from "../../assets/images/header/connect-with-us.svg";
// import ArrowSymbol from "../../assets/images/header/arrow-symbol.png";
import ArrowSymbolWhite from "../../assets/images/header/arrow-symbol-white.png";
import Logo from "../../assets/images/header/logo.png";
import Wallet from "../../assets/images/header/wallet.svg";
import DisplayProfile from "../../assets/images/header/display-profile.png";
import { UserContext } from "../context/ContextApi";
import { loginApi } from "../../services/api";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig";

const Header = () => {
  const {
    isLoggedIn,
    setIsLoggedIn,
    walletAddress,
    setWalletAddress,
    setSelectedCryptos,
    setIsAmountInvested,
    setSearchedStock,
    setIsSelectingStocks,
    setSearchedCryptosArray,
    setIsSavingStocks,
  } = useContext(UserContext);
  const navigate = useNavigate();

  async function getAccount() {
    try {
      if (window?.ethereum) {
        const accounts = await window?.ethereum
          .request({ method: "eth_requestAccounts" })
          .catch((err) => {
            if (err.code === 4001) {
              alert(err.message);
            } else {
              console.error(err);
            }
            navigate("/");
          });
        const account = accounts[0];
        return account;
      }
    } catch (error) {
      console.error(error);
      navigate("/");
    }
  }

  const siweSign = async () => {
    try {
      const account = await getAccount();
      const from = account;
      const siweMessage = `5BFVI76@&^FBG&>RB34`;
      const sign = await window?.ethereum?.request({
        method: "personal_sign",
        params: [siweMessage, from],
      });
      if (sign) {
        setWalletAddress(from);
      }
      return sign;
    } catch (error) {
      console.error(error);
      navigate("/");
    }
  };

  const login = async () => {
    try {
      const signature = await siweSign();
      const jwt = await loginApi(signature);
      if (jwt) {
        setIsLoggedIn(true);
        localStorage.setItem("token", JSON.stringify(jwt.data.userJwt));
      }
      return jwt.data;
    } catch (error) {
      console.log(error);
      navigate("/");
    }
  };

  const [isLogoutVisible, setIsLogoutVisible] = useState(false);

  const handleLogout = async () => {
    const removedAccount = await window.ethereum.request({
      method: "wallet_revokePermissions",
      params: [
        {
          eth_accounts: { walletAddress },
        },
      ],
    });
    localStorage.clear();
    setIsLogoutVisible(false);
    navigate("/");
    setIsLoggedIn(false);
    return removedAccount;
  };

  const handleRedirectToHome = () => {
    navigate("/");
    setSelectedCryptos([]);
    setIsAmountInvested(false);
    setSearchedStock("");
    setIsSelectingStocks(false);
    setSearchedCryptosArray([]);
    setIsSavingStocks(false);
  };

  const handleConnectWithUsButton = () => {
    window.open(process.env.REACT_APP_TWITTER_LINK);
  };
  return (
    <StylesHeader
      bg_color={isLoggedIn ? "#000" : "#087AF9"}
      brd_color={isLoggedIn ? "#2a2a2a" : "#087AF9"}
    >
      <div className="faq" onClick={handleConnectWithUsButton}>
        <img src={ConnectWithUs} alt="icon" className="faq-icon" />
        <span>Connect with us</span>
      </div>
      <img
        src={Logo}
        alt="logo"
        className="logo"
        onClick={handleRedirectToHome}
      />
      {isLoggedIn ? (
        <div
          className="wallet-address"
          onMouseOver={() => setIsLogoutVisible(true)}
          onMouseOut={() => setIsLogoutVisible(false)}
        >
          {isLogoutVisible ? (
            <p onClick={handleLogout}>Logout</p>
          ) : (
            <>
              <img src={DisplayProfile} alt=">" className="display-profile" />
              <span>
                {walletAddress.slice(0, 6) + "...." + walletAddress.slice(-4)}
              </span>
              <img src={ArrowSymbolWhite} alt=">" />
            </>
          )}
        </div>
      ) : (
        <p
          className="wallet-address"
          onClick={() => {
            login();
            logEvent(analytics, "connect_wallet");
          }}
        >
          <img src={Wallet} alt="icon" className="wallet" />
          <p className="button-text">Connect wallet</p>
          <img src={ArrowSymbolWhite} alt=">" />
        </p>
      )}
    </StylesHeader>
  );
};

export default Header;
