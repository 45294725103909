import React, { useContext } from "react";
import { UserContext } from "../context/ContextApi";

const Frames = ({ userETFs, stockIndex }) => {
  const { exportImage, selectedCryptos, calculatedCryptosData, roundOffPrice } =
    useContext(UserContext);
  let responseJson = {
    etfName: "My ETF",
    etfAuthor: "Phoenix",
    totalChangeUSD: 0,
    totalChangeETH: 65,
    cryptoData: [
      { name: "BTH", change: 20 },
      { name: "BCH", change: 30 },
      { name: "TRX", change: 33 },
      { name: "ETH", change: 65 },
    ],
  };

  return (
    <div
      style={{
        display: "flex",
        color: "#fff",
        border: "2px solid #1D1D1D",
        overflow: "hidden",
        borderRadius: "15px",
        height: "100%",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "60%",
          background: "#030810",
          height: "100%",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            height: "50%",
            background: "#00000099",
            padding: "20px 10px",
          }}
        >
          <h1
            style={{
              fontSize: "30px",
              overflowWrap: "break-word",
            }}
          >
            {userETFs[stockIndex]?.name.length > 25
              ? userETFs[stockIndex]?.name.slice(0, 24) + ".."
              : userETFs[stockIndex]?.name}
          </h1>
          {/* <p
            style={{
              color: "#94aab3",
              fontSize: "20px",
              padding: "0px",
              margin: "0px",
              fontWeight: 500,
            }}
          >
            by{" "}
            {responseJson.etfAuthor.length > 12
              ? responseJson.etfAuthor.slice(0, 11) + ".."
              : responseJson.etfAuthor}
          </p> */}
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            background: "#0a0d13",
            padding: "5px 10px",
            height: "35%",
            justifyContent: "center",
          }}
        >
          <p
            style={{
              color: "#BEFFBE",
              fontSize: "15px",
            }}
          >
            last 30D
          </p>
          <p
            style={{
              fontSize: "25px",
              color:
                calculatedCryptosData?.totalGainOrLossPercentageUSD_90 >= 0
                  ? "#2CEC28"
                  : "#EE0000 ",
              display: "flex",
              alignItems: "center",
            }}
          >
            {exportImage(
              calculatedCryptosData?.totalGainOrLossPercentageUSD_90,
              "25px",
              "25px"
            )}
            {calculatedCryptosData?.totalGainOrLossPercentageUSD_90
              ? Math.abs(
                  Number(
                    calculatedCryptosData?.totalGainOrLossPercentageUSD_90
                  ).toFixed(2)
                )
              : 0}
            %
          </p>
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <span
              style={{
                color: "#BEFFBE",
                fontSize: "20px",
                display: "flex",
              }}
            >
              Eth&nbsp;
              <span
                style={{
                  color:
                    calculatedCryptosData?.totalGainOrLossPercentageETH_90 > 0
                      ? "#2CEC28"
                      : "#EE0000 ",
                  verticalAlign: "middle",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {exportImage(
                  calculatedCryptosData?.totalGainOrLossPercentageETH_90,
                  "20px",
                  "20px"
                )}
                {calculatedCryptosData?.totalGainOrLossPercentageETH_90
                  ? Math.abs(
                      Number(
                        calculatedCryptosData?.totalGainOrLossPercentageETH_90
                      ).toFixed(2)
                    )
                  : 0}
                %
              </span>
            </span>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            paddingLeft: "10px",
            background: "#020911",
            height: "15%",
          }}
        >
          <svg
            width="525"
            height="51"
            viewBox="0 0 525 51"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M290.907 35.0974C288.687 35.0974 287.217 34.7352 286.5 34.0132C285.783 33.2888 285.425 32.0448 285.425 30.2744V25.3502C285.425 24.9251 285.79 24.5831 286.24 24.5831H296.138C296.59 24.5831 296.953 24.2389 296.953 23.816V16.4286C296.953 16.0034 296.587 15.6615 296.138 15.6615H286.24C285.788 15.6615 285.425 15.3173 285.425 14.8944V8.85217C285.425 8.42701 285.059 8.08508 284.61 8.08508H278.547C278.267 8.08508 278.007 8.22005 277.856 8.44276L272.529 16.3948C272.451 16.5118 272.338 16.6085 272.207 16.6715L267.066 19.1123C266.792 19.2427 266.617 19.5082 266.617 19.7984V23.816C266.617 24.2412 266.983 24.5831 267.432 24.5831H271.59C272.042 24.5831 272.405 24.9273 272.405 25.3502V31.8671C272.405 34.4406 272.969 36.6586 274.097 38.5235C275.225 40.3883 276.931 41.8123 279.221 42.7931C281.51 43.7739 284.397 44.2643 287.881 44.2643C289.726 44.2643 291.495 44.1428 293.184 43.9021C294.592 43.7019 295.818 43.4724 296.857 43.216C297.216 43.1282 297.462 42.8223 297.462 42.4736V35.2009C297.462 34.697 296.96 34.3326 296.442 34.4586C295.858 34.6003 295.235 34.724 294.566 34.8342C293.49 35.0119 292.267 35.0997 290.902 35.0997L290.907 35.0974Z"
              fill="#1B1B2A"
            />
            <path
              d="M324.625 14.7876C322.575 14.7876 320.866 15.206 319.501 16.0428C318.134 16.8797 317.007 18.0134 316.12 19.4441C315.934 19.7433 315.757 20.0492 315.587 20.3664C315.229 21.0323 314.182 20.8928 314.05 20.155L313.365 16.2993C313.298 15.9281 312.958 15.6582 312.559 15.6582H300.921C300.407 15.6582 300.02 16.1013 300.12 16.576L301.958 25.2299C301.968 25.2794 301.975 25.3289 301.975 25.3806V42.6255C301.975 43.0507 302.341 43.3926 302.79 43.3926H314.175C314.626 43.3926 314.99 43.0484 314.99 42.6255V32.7343C314.99 30.6445 315.338 29.0361 316.041 27.9113C316.741 26.7866 317.654 26.006 318.782 25.5718C319.91 25.1377 321.122 24.9195 322.419 24.9195C323.136 24.9195 323.956 24.96 324.878 25.0409C325.543 25.0994 326.269 25.1872 327.058 25.3019C327.555 25.3761 328.004 25.0162 328.004 24.5438V15.7436C328.004 15.3612 327.703 15.035 327.302 14.9833C326.9 14.9316 326.554 14.8933 326.212 14.8641C325.665 14.8168 325.137 14.7921 324.623 14.7921L324.625 14.7876Z"
              fill="#1B1B2A"
            />
            <path
              d="M356.706 16.3173L356.354 18.6613C356.259 19.3047 355.413 19.5476 354.947 19.0707C353.991 18.0922 352.913 17.2666 351.709 16.5962C349.539 15.3905 347.054 14.7876 344.253 14.7876C341.452 14.7876 338.864 15.3995 336.694 16.621C334.524 17.8425 332.832 19.5633 331.62 21.7814C330.406 23.9994 329.802 26.5886 329.802 29.5468C329.802 32.5049 330.409 35.0469 331.62 37.2649C332.832 39.4829 334.524 41.2038 336.694 42.4253C338.864 43.6468 341.383 44.2587 344.253 44.2587C347.123 44.2587 349.539 43.6468 351.709 42.4253C352.911 41.7482 353.984 40.9181 354.935 39.9373C355.401 39.4559 356.249 39.6989 356.347 40.3422L356.711 42.729C356.768 43.1069 357.112 43.3881 357.518 43.3881H369.403C369.897 43.3881 370.28 42.9764 370.21 42.5153L368.306 29.603C368.296 29.5333 368.296 29.4613 368.306 29.3915L370.21 16.5265C370.28 16.0653 369.897 15.6514 369.403 15.6514H357.521C357.114 15.6514 356.77 15.9326 356.713 16.3128L356.706 16.3173ZM354.526 32.3699C353.723 33.191 352.791 33.8411 351.733 34.3225C350.674 34.8039 349.579 35.0469 348.454 35.0469C347.328 35.0469 346.396 34.8062 345.558 34.3225C344.721 33.8411 344.071 33.1887 343.61 32.3699C343.149 31.5488 342.917 30.6085 342.917 29.549C342.917 28.4895 343.146 27.5402 343.61 26.7034C344.071 25.8665 344.719 25.2074 345.558 24.726C346.394 24.2446 347.359 24.0017 348.454 24.0017C349.548 24.0017 350.674 24.2424 351.733 24.726C352.791 25.2074 353.723 25.8688 354.526 26.7034C355.272 27.4817 355.805 28.3568 356.123 29.3286C356.17 29.4725 356.17 29.6277 356.123 29.7695C355.805 30.7413 355.272 31.6073 354.526 32.3699Z"
              fill="#1B1B2A"
            />
            <path
              d="M388.808 24.3642C389.713 23.93 390.762 23.714 391.96 23.714C393.53 23.714 394.856 24.0605 395.931 24.7511C396.859 25.3472 397.502 26.1998 397.858 27.3088C397.963 27.635 398.271 27.8622 398.632 27.8622H409.952C410.456 27.8622 410.832 27.437 410.758 26.9691C410.397 24.7038 409.46 22.7108 407.947 20.9876C406.239 19.0418 404.009 17.5233 401.261 16.4301C398.51 15.3368 395.411 14.7902 391.96 14.7902C388.201 14.7902 384.888 15.4178 382.018 16.6708C379.148 17.926 376.911 19.6536 375.305 21.8559C373.699 24.0582 372.896 26.6249 372.896 29.5493C372.896 32.4737 373.699 34.9999 375.305 37.218C376.911 39.436 379.148 41.1659 382.018 42.4031C384.888 43.6404 388.201 44.2612 391.96 44.2612C395.411 44.2612 398.51 43.7146 401.261 42.6213C404.011 41.5281 406.239 40.0006 407.947 38.039C409.462 36.3002 410.399 34.3093 410.758 32.0643C410.832 31.6031 410.445 31.1892 409.947 31.1892H398.639C398.285 31.1892 397.963 31.3962 397.85 31.7111C397.492 32.7257 396.854 33.5557 395.931 34.2036C394.856 34.9594 393.532 35.3374 391.96 35.3374C390.762 35.3374 389.713 35.1214 388.808 34.6872C387.902 34.2531 387.211 33.6097 386.733 32.7571C386.255 31.9046 386.016 30.836 386.016 29.5493C386.016 28.2626 386.255 27.1851 386.733 26.3167C387.211 25.4484 387.902 24.7983 388.808 24.3642Z"
              fill="#1B1B2A"
            />
            <path
              d="M450.054 15.6575H439.03C438.784 15.6575 438.55 15.7632 438.394 15.9432L428.869 27.0671C428.386 27.6317 427.418 27.31 427.418 26.5857V7.01929C427.418 6.59413 427.052 6.2522 426.603 6.2522H415.218C414.767 6.2522 414.403 6.59638 414.403 7.01929V42.6248C414.403 43.05 414.769 43.3919 415.218 43.3919H425.513C425.748 43.3919 425.97 43.2974 426.125 43.131L432.637 36.1777C433.01 35.7795 433.689 35.8493 433.964 36.3127L437.895 42.9938C438.038 43.239 438.313 43.3897 438.609 43.3897H451.144C451.777 43.3897 452.169 42.7396 451.844 42.2289L442.12 26.8601C441.938 26.5744 441.974 26.21 442.208 25.9581L450.666 16.9307C451.129 16.4336 450.757 15.6552 450.054 15.6552V15.6575Z"
              fill="#1B1B2A"
            />
            <path
              d="M484.917 23.2053C483.55 20.5216 481.586 18.4453 479.024 16.9831C476.462 15.5209 473.353 14.7876 469.697 14.7876C466.041 14.7876 463.068 15.3995 460.267 16.621C457.466 17.8425 455.253 19.5476 453.63 21.7341C452.008 23.9207 451.195 26.4784 451.195 29.4028C451.195 32.3272 452.032 34.9591 453.707 37.1929C455.38 39.4289 457.66 41.1656 460.549 42.4028C463.436 43.6401 466.705 44.2609 470.361 44.2609C473.607 44.2609 476.52 43.9235 479.098 43.2486C481.524 42.6143 483.777 41.7077 485.857 40.5312C486.1 40.394 486.248 40.142 486.248 39.8743V34.1021C486.248 33.5397 485.632 33.1752 485.087 33.4069C483.706 33.9918 482.231 34.4575 480.661 34.8084C478.644 35.2583 476.579 35.4833 474.46 35.4833C472.17 35.4833 470.275 35.2111 468.772 34.6622C468.375 34.5182 468.005 34.3562 467.661 34.1763C466.92 33.7894 467.204 32.7321 468.05 32.7321H486.134C486.595 32.7321 486.963 32.3722 486.951 31.9403C486.858 28.6065 486.179 25.6956 484.913 23.2053H484.917ZM467.006 23.7835C467.878 23.3178 468.944 23.0839 470.208 23.0839C471.3 23.0839 472.24 23.2841 473.026 23.6867C473.812 24.0894 474.453 24.6765 474.947 25.4481C475.11 25.7023 474.916 26.0262 474.601 26.0262H464.831C464.886 25.9115 464.944 25.799 465.008 25.6888C465.469 24.8857 466.136 24.2491 467.006 23.7835Z"
              fill="#1B1B2A"
            />
            <path
              d="M515.46 14.8596C514.913 14.8123 514.385 14.7876 513.871 14.7876C511.821 14.7876 510.112 15.206 508.748 16.0428C507.381 16.8797 506.253 18.0134 505.366 19.4441C505.18 19.7433 505.003 20.0492 504.833 20.3664C504.475 21.0323 503.428 20.8928 503.296 20.155L502.611 16.2993C502.544 15.9281 502.204 15.6582 501.805 15.6582H490.167C489.653 15.6582 489.266 16.1013 489.367 16.576L491.204 25.2299C491.214 25.2794 491.221 25.3289 491.221 25.3806V42.6255C491.221 43.0507 491.587 43.3926 492.036 43.3926H503.421C503.872 43.3926 504.236 43.0484 504.236 42.6255V32.7343C504.236 30.6445 504.585 29.0361 505.287 27.9113C505.987 26.7866 506.9 26.006 508.028 25.5718C509.156 25.1377 510.368 24.9195 511.665 24.9195C512.382 24.9195 513.202 24.96 514.125 25.0409C514.789 25.0994 515.515 25.1872 516.304 25.3019C516.801 25.3761 517.25 25.0162 517.25 24.5438V15.7436C517.25 15.3612 516.949 15.035 516.548 14.9833C516.146 14.9316 515.8 14.8933 515.458 14.8641L515.46 14.8596Z"
              fill="#1B1B2A"
            />
            <path
              d="M251.58 0.999756H209.865C207.243 0.999756 205.013 3.01473 205.013 5.62567V44.8928C205.013 47.5038 207.243 49.5187 209.865 49.5187H251.58C254.202 49.5187 256.432 47.5038 256.432 44.8928V5.62567C256.432 3.01473 254.202 0.999756 251.58 0.999756Z"
              stroke="#1B1B2A"
              stroke-width="2"
            />
            <path
              d="M241.361 8.24548H226.788C226.031 8.24548 225.417 8.82406 225.417 9.53615V13.9527C225.417 14.6648 226.031 15.2434 226.788 15.2434H232.798C234.019 15.2434 234.631 16.6335 233.766 17.4451L222.416 28.1291C221.554 28.9407 220.077 28.3673 220.077 27.218V21.5606C220.077 20.8485 219.462 20.2699 218.706 20.2699H214.014C213.257 20.2699 212.643 20.8485 212.643 21.5606V40.986C212.643 41.6981 213.257 42.2767 214.014 42.2767H234.65C235.407 42.2767 236.021 41.6981 236.021 40.986V36.5695C236.021 35.8574 235.407 35.2788 234.65 35.2788H228.64C227.419 35.2788 226.807 33.8887 227.672 33.0771L239.022 22.3931C239.884 21.5815 241.361 22.1548 241.361 23.3041V28.9616C241.361 29.6737 241.976 30.2523 242.732 30.2523H247.424C248.181 30.2523 248.795 29.6737 248.795 28.9616V9.53615C248.795 8.82406 248.181 8.24548 247.424 8.24548H241.361Z"
              fill="#1B1B2A"
            />
            <path
              d="M20.8765 16.5558V36.3038H17.6322V22.2263L11.6231 32.1567H11.2282L5.21912 22.1981V36.3038H1.9748V16.5558H5.47303L11.4257 26.4298L17.4065 16.5558H20.8765ZM45.8598 36.3038L44.4774 32.326H36.0704L34.688 36.3038H31.1616L38.3273 16.5558H42.2205L49.358 36.3038H45.8598ZM37.1424 29.2792H43.4054L40.2739 20.3643L37.1424 29.2792ZM67.421 16.5558C70.1293 16.5558 72.3769 17.5056 74.1636 19.4052C75.9691 21.3047 76.8719 23.6463 76.8719 26.4298C76.8719 29.1945 75.9691 31.5361 74.1636 33.4545C72.3769 35.354 70.1293 36.3038 67.421 36.3038H59.6629V16.5558H67.421ZM67.421 33.2006C69.283 33.2006 70.797 32.5611 71.9631 31.2822C73.148 30.0033 73.7404 28.3858 73.7404 26.4298C73.7404 24.455 73.148 22.8376 71.9631 21.5774C70.797 20.2985 69.283 19.6591 67.421 19.6591H62.9072V33.2006H67.421ZM91.2553 33.2006H100.001V36.3038H88.011V16.5558H99.8598V19.6591H91.2553V24.7935H99.1545V27.8686H91.2553V33.2006ZM142.89 33.7084C140.915 35.6832 138.498 36.6706 135.64 36.6706C132.781 36.6706 130.355 35.6832 128.361 33.7084C126.386 31.7148 125.399 29.2886 125.399 26.4298C125.399 23.571 126.386 21.1543 128.361 19.1795C130.355 17.1858 132.781 16.189 135.64 16.189C138.498 16.189 140.915 17.1858 142.89 19.1795C144.884 21.1543 145.88 23.571 145.88 26.4298C145.88 29.2886 144.884 31.7148 142.89 33.7084ZM130.646 31.5079C132 32.8432 133.665 33.5109 135.64 33.5109C137.615 33.5109 139.27 32.8432 140.605 31.5079C141.959 30.1537 142.636 28.461 142.636 26.4298C142.636 24.3986 141.959 22.7153 140.605 21.38C139.27 20.0258 137.615 19.3487 135.64 19.3487C133.665 19.3487 132 20.0258 130.646 21.38C129.311 22.7153 128.643 24.3986 128.643 26.4298C128.643 28.461 129.311 30.1537 130.646 31.5079ZM168.87 16.5558H172.114V36.3038H169.575L160.265 22.9034V36.3038H157.021V16.5558H159.56L168.87 29.9562V16.5558Z"
              fill="#1B1B2A"
            />
          </svg>
        </div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          backgroundColor: "#0d0f15",
          width: "40%",
          height: "100%",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        className="frame-right-section"
      >
        {selectedCryptos?.map((data, uniqueData) => {
          return (
            <div
              key={uniqueData}
              style={{
                display: "flex",
                justifyContent: "space-between",
                padding: "5px",
                borderBottom: "1px solid #555454",
              }}
            >
              <p style={{ fontSize: "20px", padding: "0px 5px" }}>
                {data?.cryptoName.length > 4
                  ? data?.cryptoName.slice(0, 6) + ".."
                  : data?.cryptoName}
              </p>
              <p style={{ fontSize: "20px", padding: "0px 5px" }}>
                {Math.abs(roundOffPrice(Number(data?.percent_change_24h)))}%
              </p>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Frames;
