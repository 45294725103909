import styled from "styled-components";

export const StylesApp = styled.div`
  background-color: #151515;
  width: 100%;
  padding: 15px 0;
  border-radius: 15px;
  border: 1px solid #313131;
  height: calc(65vh - 60px);
  overflow: hidden;
  /* &:last-child {
    margin-bottom: 150px;
  } */
  .bg-image {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px;
  }

  .end-text {
    color: #494949;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
  }

  .stock-list-block {
    overflow: auto;
    height: calc(60vh - 158px);

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-track {
      margin: 2px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: #040404;
    }
    &::-webkit-scrollbar-thumb:hover {
      border-radius: 5px;
      background: #383838;
    }

    /* background: linear-gradient(#151515 100%, rgba(0, 0, 0, 0)),
      linear-gradient(rgba(0, 0, 0, 0), #151515 100%) 0 100%,
      radial-gradient(
        farthest-side at 50% 0%,
        rgba(0, 0, 0, 1),
        rgba(0, 0, 0, 0.1)
      ),
      radial-gradient(
          farthest-side at 50% 100%,
          rgba(0, 0, 0, 1),
          rgba(0, 0, 0, 0.1)
        )
        0 100%;
    background-repeat: no-repeat;
    background-attachment: local, local, scroll, scroll;
    background-size: 100% 100px, 100% 100px, 100% 30px, 100% 30px;
    z-index: 1; */
  }
`;

export const StylesButton = styled.button`
  padding: 5px 10px 5px 10px;
  border: ${(props) => props.button_border || "2px solid #3b3939"};
  color: ${(props) => props.button_text_color || "#434343"};
  font-size: 16px;
  font-weight: 500;
  border-radius: 35px;
  cursor: pointer;
  background-color: ${(props) => props.button_bg_color || "#1d1d1d"};

  /* &:hover {
    opacity: 0.8;
  } */
  svg {
    margin-left: 5px;
  }
`;

export const StylesAddCryptoHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border-bottom: 1px solid #313131;
`;

export const StylesAddCryptoHeader = styled.div`
  div {
    display: flex;
    align-items: center;
    color: #fff;
    padding-bottom: 5px;

    img {
      margin-right: 10px;
      height: 10px;
      width: 10px;
      cursor: pointer;
    }

    h2 {
      font-size: 18px;
      font-weight: 700;
    }
  }
  p {
    color: #878787;
    font-size: 12px;
    font-weight: 500;
    padding-bottom: 15px;
  }
`;

export const StylesSearchBar = styled.div`
  background-color: #000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  margin: 10px 20px;
  border-radius: 5px;

  img {
    margin-right: 10px;
    height: 16px;
    width: 16px;
  }

  input {
    background-color: #000;
    caret-color: #fff;
    border: none;
    outline: none;
    width: 80%;
    color: #fff;

    & ::placeholder {
      color: #5b5b5b;
      opacity: 1;
    }
  }
`;

export const StylesStocksList = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2f2f2f;
  padding: 15px;
  cursor: pointer;

  /* & :hover {
    background-color: red;
  } */
  &:last-child {
    border-bottom: none;
  }

  .stock-name,
  .stock-price {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  .stock-suffix {
    font-size: 16px;
    font-weight: 600;
    color: #9f9f9f;
  }
`;

export const StylesStocksListLeftSection = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 10px;
    height: 26px;
    width: 26px;
  }

  i {
    margin: 0 5px;
    color: #c7c7c7;
  }
`;

export const StylesStocksListRightSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: end;
  letter-spacing: 1.3px;

  .stock-price {
    margin-bottom: 2px;
  }

  .stock-status {
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.statusColor || "#18AB16"};
    background-color: ${(props) => props.statusBackgroundColor || "#04bb001a"};
    padding: 3px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const StylesSavedStocksList = styled.div`
  height: calc(65vh - 150px);
  overflow: auto;
  padding: 15px;
  margin: 5px;
  & :last-child {
    margin-bottom: 0px;
  }
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    margin: 2px;
  }

  &::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #383838;
  }

  &::-webkit-scrollbar-thumb:hover {
    border-radius: 5px;
    background: #494949;
  }
`;

export const StylesSavedStocks = styled.div`
  /* display: flex;
  justify-content: space-between; */
  background-color: #0d0d0d;
  border-radius: 15px;
  cursor: pointer;
  padding: 10px;
  border: 1px solid #313131;
  margin-bottom: 20px;
  height: 130px;

  &:hover {
    background-color: ${(props) => props.bg_color || "#31313150"};
    border: 2px solid ${(props) => props.border || "#0d0d0d"} !important;
    transition: 500ms all;
  }

  .left {
    width: 60%;
    float: left;
    overflow-wrap: break-word;
  }

  .right {
    width: 40%;
    float: left;
  }

  ::after {
    content: "";
    display: table;
    clear: both;
  }

  h2 {
    font-size: 24px;
    font-weight: 700;
    color: #fff;

    .popUpWrapper {
      margin-top: 20px;
      background-color: #1d1d1d;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  .today-value,
  .total-value,
  .today-header,
  .total-header {
    display: flex;
    justify-content: space-between;
  }

  .today-value {
    margin-bottom: 20px;
  }

  .today-usd-value {
    color: ${(props) => props.today_usd_status || "#18AB16"};
    font-size: 16px;
    font-weight: 600;
  }

  .total-usd-value {
    color: ${(props) => props.total_usd_status || "#18AB16"};
    font-size: 16px;
    font-weight: 600;
  }

  .today-eth-value {
    color: ${(props) => props.today_eth_status || "#18AB16"};
    font-size: 16px;
    font-weight: 600;
  }

  .total-eth-value {
    color: ${(props) => props.total_eth_status || "#18AB16"};
    font-size: 16px;
    font-weight: 600;
  }

  .today-header,
  .total-header {
    color: #878787;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
  }
`;

export const StylesSaveCrypto = styled.div`
  .stock-group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    padding: 15px;
    border-bottom: 1px solid #313131;

    h1 {
      font-size: 24px;
      font-weight: 700;
    }

    img {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }
  }

  .selected-stocks {
    overflow: auto;
    height: 280px;
  }
`;

export const StylesSelectedSaveCryptos = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 15px;
  align-items: center;

  .profile-image {
    margin: 0 10px;
    height: 26px;
    width: 26px;
  }

  i {
    margin: 0 5px;
    color: #c7c7c7;
  }

  .stock-name {
    font-size: 16px;
    font-weight: 600;
    color: #fff;
  }

  .stock-suffix {
    font-size: 16px;
    font-weight: 600;
    color: #9f9f9f;
  }
`;

export const StylesAddNewStocks = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #151515;
  border-radius: 15px;
  cursor: pointer;
  padding: 10px;
  border: 1px dashed #313131;
  margin-bottom: 20px;
  height: 130px;
  color: #828282;
  font-size: 20px;
`;

export const StylesSaveCryptosRightSide = styled.div`
  color: white;
  font-size: 20px;
  display: flex;
  align-items: center;
  font-weight: 600;
  span {
    cursor: pointer;
  }
  .stocks-percentage {
    font-size: 16px;
    border: 1px solid #404040;
    border-radius: 5px;
    padding: 5px 10px;
    color: #fff;
    margin: 0 10px;
    background-color: #1f1f1f;
    width: 75px;
    overflow: hidden;
  }
`;

export const StylesViewWrapper = styled.div`
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #313131;

  h5 {
    font-size: 12px;
    font-weight: 500;
    color: #878787;
    margin-bottom: 5px;
  }

  .left-div,
  .right-div {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    padding: 3px 10px;
  }

  .choose-view {
    display: flex;
    border: 1px solid #fff;
    border-radius: 15px;
  }

  .divider-span {
    border-left: 1px solid #fff;
    border-right: 1px solid #fff;
  }

  .divider-span-2 {
    border-left: 1px solid #fff;
  }
`;

export const StylesFormWrapper = styled.div`
  .button-wrapper {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }
`;
