import React from "react";
import "./style.js"; // Import CSS file for styling
import { StylesLoader } from "./style.js";

const Loader = ({ height, width }) => {
  return (
    <StylesLoader className="loader-container" height={height} width={width}>
      <div className="loader"></div>
    </StylesLoader>
  );
};

export default Loader;
