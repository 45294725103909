import React, { useContext, useEffect } from "react";
import { StartInvest, StartInvestHeader, StartInvestWrap } from "./styles";
import { CommonWrapper } from "../AddedCryptos/styles";
import Profile from "../../assets/images/addedCryptos/profile.png";
import RightArrow from "../../assets/images/addedCryptos/rightArrow.svg";
import { UserContext } from "../context/ContextApi";
import { useNavigate } from "react-router-dom";
import { logEvent } from "firebase/analytics";
import { analytics } from "../../firebaseConfig";

import { getUserETFs } from "../../services/api";
const CreateETF = () => {
  const { isLoggedIn, walletAddress } = useContext(UserContext);
  const navigate = useNavigate();

  const handleClick = () => {
    const token = localStorage.getItem("token");
    if (token !== "" && token !== null) {
      navigate("/add-crypto");
    } else {
      alert("connect your wallet");
    }
  };

  const fetchUserETFs = async () => {
    try {
      const ETFs = await getUserETFs();
      if (ETFs?.data?.length > 0) {
        navigate("/dashboard");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserETFs();
    }
  }, [isLoggedIn]);

  return (
    <StartInvestWrap>
      <StartInvestHeader>
        <CommonWrapper jc="space-between">
          <div className="customETF">
            <p>Your custom ETFs</p>
            <span>Managed by you</span>
          </div>
          {walletAddress !== "" && (
            <div className="profileWrap">
              <CommonWrapper gap="10px">
                <img src={Profile} alt="profile" />
                <p>
                  {walletAddress.slice(0, 6) + "...." + walletAddress.slice(-4)}
                </p>
                <img className="rightArrow" src={RightArrow} alt="rightArrow" />
              </CommonWrapper>
            </div>
          )}
        </CommonWrapper>
      </StartInvestHeader>
      <StartInvest>
        <h2>Create and track your investment Ideas</h2>
        <div
          className="createETF"
          onClick={() => {
            handleClick();
            logEvent(analytics, "create_etf");
          }}
        >
          Create your first Idea
        </div>
      </StartInvest>
    </StartInvestWrap>
  );
};

export default CreateETF;
