import React from "react";
import { useNavigate } from "react-router-dom";

const PageNotFound = () => {
  const navigate = useNavigate();

  const styles = {
    border: "1px solid #313131",
    fontSize: "16px",
    cursor: "pointer",
    color: "#808080",
    marginTop: "15px",
    padding: "10px",
    borderRadius: "10px",
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        paddingTop: "50px",
      }}
    >
      <h1>404 Error</h1>
      <h1 style={{ textAlign: "center" }}>Page Not Found</h1>
      <h2 style={styles} onClick={() => navigate("/")}>
        Go to Home Page
      </h2>
    </div>
  );
};

export default PageNotFound;
