import React, { useContext } from "react";
import { AddedContainer, CommonWrapper, SelectedCryptoWrap } from "./styles";
import AddedCryptosBg from "../../assets/images/addedCryptos/trackerBg.png";
import close from "../../assets/images/addedCryptos/close.svg";
import { UserContext } from "../context/ContextApi";
import { useLocation } from "react-router-dom";
import Loader from "../Loader";
import { Tooltip } from "react-tooltip";

const AddedCryptos = ({ isCalculatedLoading, isETFCryptosLoading }) => {
  const {
    selectedCryptos,
    setSelectedCryptos,
    stockName,
    detectStockStatusColor,
    detectStockStatusBackgroundColor,
    roundOffPrice,
    priceChangeIn24Hours,
    calculatedCryptosData,
    exportImage,
  } = useContext(UserContext);
  const location = useLocation();
  const { pathname } = location;
  const onDisSelect = (id) => {
    const clear = selectedCryptos?.filter((val) => val?.id !== id);
    setSelectedCryptos(clear);
  };

  return (
    <AddedContainer>
      <div className="header">
        <h3>Added tokens</h3>
        <p className="commonTextStyle">Tokens you have selected to track</p>
      </div>

      {isETFCryptosLoading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignContent: "center",
          }}
        >
          <Loader height={"20px"} width={"20px"} />
          <span style={{ paddingLeft: "5px" }}>Loading..</span>
        </div>
      ) : !selectedCryptos?.length > 0 ? (
        <div className="selectCrypto">
          <img src={AddedCryptosBg} alt="background " />
          <p className="view">Select a token to view here</p>
        </div>
      ) : (
        <div className="SelectedCryptosContainer">
          {selectedCryptos?.map((val, ind) => {
            return (
              <SelectedCryptoWrap key={ind}>
                <CommonWrapper jc="space-between">
                  <CommonWrapper>
                    <img src={val?.logo} alt="logo" className="logo" />
                    <h4
                      data-tooltip-id="stock-name-tooltip"
                      data-tooltip-content={val?.cryptoName}
                    >
                      {stockName(val?.cryptoName)}
                      <span className="dot"></span>
                      <span className="shortForm">{val.symbol}</span>
                    </h4>
                  </CommonWrapper>
                  {pathname === "/dashboard" || pathname === "/dashboard/" ? (
                    ""
                  ) : (
                    <img
                      src={close}
                      alt="close"
                      style={{
                        cursor: "pointer",
                        padding: "2px",
                        height: "12px",
                        width: "12px",
                      }}
                      onClick={() => {
                        onDisSelect(val.id);
                      }}
                    />
                  )}
                </CommonWrapper>
                <CommonWrapper
                  jc="flex-start"
                  statusColor={detectStockStatusColor(
                    Number(val?.percent_change_24h)
                  )}
                  statusBackgroundColor={detectStockStatusBackgroundColor(
                    Number(val?.percent_change_24h)
                  )}
                >
                  <h4>${roundOffPrice(Number(val?.currentPrice))}</h4>
                  <p className="stock-growth">
                    {exportImage(val?.percent_change_24h, "12px", "12px")} $
                    {Math.abs(
                      roundOffPrice(
                        priceChangeIn24Hours(
                          Number(val.currentPrice),
                          Number(val?.percent_change_24h)
                        )
                      )
                    )}
                    &nbsp;(
                    {Math.abs(roundOffPrice(Number(val?.percent_change_24h)))}
                    %)
                  </p>
                </CommonWrapper>
                <Tooltip id="stock-name-tooltip" />
              </SelectedCryptoWrap>
            );
          })}
        </div>
      )}
      {selectedCryptos?.length > 1 ? (
        <div className="footer">
          {isCalculatedLoading ? (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
              }}
            >
              <Loader height={"20px"} width={"20px"} />
              <span style={{ paddingLeft: "5px" }}>Loading..</span>
            </div>
          ) : (
            // <CommonWrapper jc="flex-start">
            //   <p className="commonTextStyle">Min.Amt</p>
            //   <h3>$145.32</h3>
            // </CommonWrapper>
            <CommonWrapper jc="flex-start" ai="baseline">
              <p className="commonTextStyle">Past 30D:</p>
              <h3
                style={{ display: "flex", alignItems: "center" }}
                className={
                  calculatedCryptosData?.totalGainOrLossPercentageUSD_90 >= 0
                    ? "up"
                    : "down"
                }
              >
                {exportImage(
                  calculatedCryptosData?.totalGainOrLossPercentageUSD_90,
                  16,
                  16
                )}
                {calculatedCryptosData?.totalGainOrLossPercentageUSD_90
                  ? Math.abs(
                      Number(
                        calculatedCryptosData?.totalGainOrLossPercentageUSD_90
                      ).toFixed(2)
                    )
                  : 0}
                %
              </h3>{" "}
              <span className="commonTextStyle">| </span>
              <p>Eth</p>
              <h3
                className={
                  calculatedCryptosData?.totalGainOrLossPercentageETH_90 >= 0
                    ? "up"
                    : "down"
                }
                style={{ display: "flex", alignItems: "center" }}
              >
                {exportImage(
                  calculatedCryptosData?.totalGainOrLossPercentageETH_90,
                  16,
                  16
                )}
                {calculatedCryptosData?.totalGainOrLossPercentageETH_90
                  ? Math.abs(
                      Number(
                        calculatedCryptosData?.totalGainOrLossPercentageETH_90
                      ).toFixed(2)
                    )
                  : 0}
                %
              </h3>
            </CommonWrapper>
          )}
        </div>
      ) : (
        ""
      )}
    </AddedContainer>
  );
};

export default AddedCryptos;
