import React, { useState } from "react";
import Dashboard, { AddCryptosHome } from "../../components/AddCryptos";
import { StylesCryptoHome, StylesHeaderWrapper } from "./styles";
import AddedCryptos from "../../components/AddedCryptos/addedCryptos";
import { useLocation } from "react-router-dom";
const Stocks = () => {
  const [isCalculatedLoading, setIsCalculatedResultLoading] = useState(true);
  const [isETFCryptosLoading, setIsETFCryptosLoading] = useState(true);

  const location = useLocation();
  const { pathname } = location;

  return (
    <>
      <StylesCryptoHome>
        <div className="add-cryptos-home">
          {pathname === "/add-crypto" || pathname === "/add-crypto/" ? (
            <AddCryptosHome
              isCalculatedLoading={isCalculatedLoading}
              setIsCalculatedResultLoading={setIsCalculatedResultLoading}
              setIsETFCryptosLoading={setIsETFCryptosLoading}
            />
          ) : (
            <Dashboard
              setIsETFCryptosLoading={setIsETFCryptosLoading}
              setIsCalculatedResultLoading={setIsCalculatedResultLoading}
            />
          )}
        </div>
        <div className="added-cryptos-home">
          <AddedCryptos
            isCalculatedLoading={isCalculatedLoading}
            isETFCryptosLoading={isETFCryptosLoading}
          />
        </div>
      </StylesCryptoHome>
    </>
  );
};

export default Stocks;
