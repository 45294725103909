import styled from "styled-components";
export const ShareContainer = styled.div`
  position: absolute;
  z-index: 1;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(8px);

  .container {
    max-width: 920px;
    padding: 0px 30px;
    border-radius: 20px;
    overflow: hidden;
  }
`;

export const Navigators = styled.div`
  background: #1e1e1e;
  padding: 20px;
  margin: 0 -30px;
  text-align: right;
  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: left;
  }

  button {
    font-size: 20px;
    font-weight: 700;
    line-height: 26.4px;
    color: #fff;
    border: none;
    border-radius: 25px;
    padding: 10px 20px;
    background: #087af9;
    cursor: pointer;
  }

  .cancel {
    background: transparent;
  }
`;

export const ProfileWrap = styled.div`
  background: #151515;
  padding: 30px 0px;
  margin: 0 -30px;

  .leftSection {
    flex-basis: 45%;
  }

  .profileWrapper {
    margin: 0 0 15px 3px;
  }

  p {
    font-size: 20px;
    font-weight: 700;
    line-height: 26.4px;
    text-align: left;
  }
`;
export const MessageBox = styled.div`
  background: #2b2b2b;
  padding: 15px;
  border-radius: 10px;
  position: relative;

  &::before {
    content: "";
    width: 15px;
    height: 15px;
    left: 9px;
    background: #2b2b2b;
    display: block;
    position: absolute;
    top: -7px;
    transform: rotate(45deg);
  }

  p {
    font-size: 16px;
    font-weight: 500;
    line-height: 17.6px;
    text-align: left;
  }
`;
export const PopUp = styled.div`
  background: #000;
  padding: 5px 20px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  width: fit-content;
  position: absolute;
  transform: translateY(-50%);
  right: 10%;
  top: 10%;
  border-radius: 10px;
  color: #18ab16;
  border: 2px solid #1d1d1d;
  z-index: 1;
`;
