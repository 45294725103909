import styled from "styled-components";
import addedBg from "../../assets/images/addedCryptos/trackerBg.png";

export const AddedContainer = styled.div`
  position: relative;
  background: #151515;
  border: 1px solid #313131;
  // width: 350px;

  height: calc(65vh - 60px);
  border-radius: 15px;
  color: #fff;
  overflow: hidden;

  .SelectedCryptosContainer {
    height: calc(60vh - 170px);
    overflow-y: auto;
  }
  .SelectedCryptosContainer::-webkit-scrollbar {
    width: 5px;
  }

  .SelectedCryptosContainer::-webkit-scrollbar-track {
    background: none;
  }

  .SelectedCryptosContainer::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #494949;
  }

  .SelectedCryptosContainer::-webkit-scrollbar-thumb:hover {
    border-radius: 5px;
    background: #383838;
  }

  .footer {
    text-align: left;
    padding: 16px;
    background: #313131;
    position: absolute;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;

    .up {
      color: #18ab16;
    }
    .down {
      color: #ee0000;
    }
  }

  .commonTextStyle {
    text-wrap: nowrap;
    font-size: 16px;
    font-weight: 500;
    text-align: left;
    color: #494949;
  }

  .selectCrypto {
    position: absolute;
    top: 55%;
    text-align: center;
    left: 50%;
    transform: translate(-50%, -50%);

    p {
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      color: #494949;
    }
  }

  .header {
    padding: 15px;
    border-bottom: 1px solid #313131;

    h3 {
      font-size: 18px;
      font-weight: 700;
      padding-bottom: 5px;
    }
    p {
      font-size: 12px;
      font-weight: 500;
      color: #878787;
    }
  }
  h4 {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 10px;

    .dot {
      display: inline-block;
      width: 5px;
      height: 5px;
      background: #fff;
      border-radius: 50%;
    }
  }
`;

export const SelectedCryptoWrap = styled.div`
  padding: 16px;
  border-bottom: 1px solid #313131;
  .shortForm {
    color: #9f9f9f;
  }
`;

export const CommonWrapper = styled.div`
  margin-bottom: 1px;
  display: flex;
  justify-content: ${(props) => props?.jc || "center"};
  align-items: ${(props) => props.ai || "center"};
  gap: ${(props) => props.gap || "10px"};

  .logo {
    height: 26px;
    width: 26px;
  }

  .stock-growth {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
    font-weight: 600;
    color: ${(props) => props.statusColor || "#18AB16"};
    background-color: ${(props) => props.statusBackgroundColor || "#04bb001a"};
    padding: 3px;
    border-radius: 5px;
  }

  .faq {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #087af9;
    padding: 5px;
    border-radius: 56px;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    cursor: pointer;
    border: 1px solid #087af9;
    &:hover {
      opacity: 0.8;
    }
    .faq-icon {
      height: 14px;
      width: 14px;
    }

    span {
      padding: 0 5px;
    }
  }
`;
