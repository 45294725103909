import axios from "axios";

const BASE_URL = process.env.REACT_APP_BACKEND_URL
  ? process.env.REACT_APP_BACKEND_URL
  : "https://uat.revise.network/tracker/api";

const getHeader = () => {
  const jwtToken = JSON.parse(localStorage.getItem("token"));

  return {
    headers: { Authorization: `Bearer ${jwtToken}` },
  };
};

const loginApi = async (signature) => {
  const jwtToken = await axios.post(`${BASE_URL}/auth/login`, {
    signature,
  });
  return jwtToken?.data;
};

axios.interceptors.response.use(
  (response) => {
    // response;
    return response;
  },

  async (error) => {
    const token = window.localStorage.getItem("token");

    if (
      error?.response?.data?.code === "E500" ||
      token === "" ||
      token === null
    ) {
      localStorage.clear();
      window.location.href = "/";
    }
    return Promise.reject(error);
  }
);

const searchCryptoApi = async (name, currentPageNumber) => {
  const fetchedCryptos = await axios.get(
    `${BASE_URL}/user/searchCrypto?search=${name}&perPage=10&currPage=${currentPageNumber}`,
    getHeader()
  );
  return fetchedCryptos?.data;
};

const addCryptoToUser = async (
  cryptoIds,
  ETFName,
  viewType,
  weightageSchema,
  amountToBeInvested = 100
) => {
  const addedCryptoResult = await axios.post(
    `${BASE_URL}/user/addETF`,
    {
      cryptoData: cryptoIds,
      etfName: ETFName,
      viewType,
      weightageSchema,
      amountToBeInvested,
    },
    getHeader()
  );

  return addedCryptoResult;
};

const getUserETFs = async () => {
  const userETFs = await axios.get(
    `${BASE_URL}/user/fetchUserETF`,
    getHeader()
  );
  return userETFs?.data;
};

const getUserETFsCryptos = async (etfId) => {
  const userCryptos = await axios.get(
    `${BASE_URL}/user/fetchETFCryptos?etfId=${etfId}`,
    getHeader()
  );
  return userCryptos?.data;
};

const calculateCryptos = async (
  selectedCryptoData,
  viewType,
  totalAmountToBeInvested = 100
) => {
  const calculatedData = await axios.post(
    `${BASE_URL}/user/calculateCryptos`,
    {
      selectedCryptoData,
      viewType,
      totalAmountToBeInvested,
    },
    getHeader()
  );

  return calculatedData?.data;
};

export {
  searchCryptoApi,
  addCryptoToUser,
  getUserETFs,
  getUserETFsCryptos,
  loginApi,
  calculateCryptos,
};
